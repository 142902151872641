.containe-generate {
    background-image: url("./img/Burger-king-fondo.png");
    height: auto;
    object-fit: cover;
    height: 100vh;
}



@media only screen and (max-width: 992px) {
    .containe-generate {
        background-image: url("./img/Burger-king-fondocell.png");
    }
}

.content-initial {
    margin: 10px;
    padding:40px;
    background-color: white;
}

.logo-content {
    top: 2.5rem;
}

.content-text-all{
    padding: 1rem 2rem 0rem 2rem;
}

.card-order {
    background-color: white;
}

.content-list-orders {
    overflow-y: scroll;
    height: 80%;
  
}

.content-list-orders::-webkit-scrollbar {
    display: none;
   }

