/* Estilos para el contenedor principal */
.kds-main {
   background-color: #000940;
}

.container-kds {
   display: flex;
   flex-direction: column;
   height: 100%;
}

/* Estilos para el header */
.header {
   /* height: 40px; */
}

/* Estilos para el main */
.main {
   /* Permite que el main ocupe el espacio restante vertical */
   flex: 1;
   /* Puedes agregar más estilos aquí */
}

.kds-header {
   position: sticky;
   top: 0;
   left: 0;
   z-index: 1999;
}

.logo {
   height: 40px;
}

.kds-container {
   width: 100%;
   height: 688px;
}

.kds-grid {
   margin-top: 50px;
   flex: 1;
   grid-template-columns: 3fr 1fr
}

.kds-list-orders,
.kds-view {
   height: 100%;
   /* background-color: #D9D9D9; */
}

/* .kds-tab{

} */

.kds-view-details {
   border-bottom: 2px dashed #c6c6c6;
}

.kds-list-orders {
   background-color: #000940;
}

.card-seen,
.card-no-seen {
   max-width: 18rem;
}

.container-header {
   flex: 3;
   height: 50px;
}

/* Estilos para el contenedor padre */
.container-parent {
   display: flex;
   height: 100vh;
}


/* Estilos para el contenedor izquierdo */
.container-left {
   flex: 3;
   /* El contenedor izquierdo ocupará el espacio restante */
   /* Se oculta cualquier contenido que exceda el tamaño del contenedor */
   overflow: auto;
   position: relative;
}

/* Estilos para el contenedor derecho */
.container-right {
   flex: 1;
   /* El contenedor derecho ocupará 3 veces el espacio del contenedor izquierdo */
   overflow: auto;
}

.kds-view-top {
   width: 100%;
   padding: 20px 0;
   position: absolute;
   top: 0;
   z-index: 1999;
}

.kds-view-bottom {
   width: 100%;
   padding: 20px 0;
   position: absolute;
   bottom: 0;
   z-index: 1999;
}

.flex-container {
   color: #000000;
   display: flex;
   justify-content: flex-start;
   align-items: stretch;
   flex-direction: column;
   flex-wrap: nowrap;
   align-content: flex-start;
   background-color: #ffffff;
   height: 100%;
   gap: 1px;

}

.flex-container>div {
   border-radius: 5px;
   padding: 8px;
}


.item2 {
   /* flex:3 1 auto; */
   order: 0;
   flex-grow: 1;
   overflow: auto;
}



.kds-card-header {
   font-weight: 600;
   padding: 5px;
   margin-bottom: 0;
   color: #212748;
   /* background-color: rgba(0, 0, 0, 0.03); */
   background-color: #D9D9D9;
   border-bottom: 1px solid rgba(0, 0, 0, 0.175);
}

.kds-card-header:first-child {
   border-radius: calc(0.375rem - 1px) calc(0.375rem - 1px) 0 0;
   -webkit-border-radius: calc(0.375rem - 1px) calc(0.375rem - 1px) 0 0;
   -moz-border-radius: calc(0.375rem - 1px) calc(0.375rem - 1px) 0 0;
   -ms-border-radius: calc(0.375rem - 1px) calc(0.375rem - 1px) 0 0;
   -o-border-radius: calc(0.375rem - 1px) calc(0.375rem - 1px) 0 0;
}

.kds-card-body {
   flex: 1 1 auto;
   padding: 0.5rem 0.5rem;
   background-color: transparent;
   /*color: var(--bs-card-color);*/
   border-radius: calc(0.375rem - 1px) calc(0.375rem - 1px) 0 0;
   -webkit-border-radius: calc(0.375rem - 1px) calc(0.375rem - 1px) 0 0;
   -moz-border-radius: calc(0.375rem - 1px) calc(0.375rem - 1px) 0 0;
   -ms-border-radius: calc(0.375rem - 1px) calc(0.375rem - 1px) 0 0;
   -o-border-radius: calc(0.375rem - 1px) calc(0.375rem - 1px) 0 0;

   overflow: auto;
   height: 150px;

}

.kds-empty-orders {
   padding: 20px 10px;
}

.kds-img-empty {
   width: 100px;
   height: 100px;

}

.nav-link {
   display: block;
   padding: 0.5rem 1rem;
   font-weight: 600;
   color: #212748;
   ;
   text-decoration: none;
   transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out;
}

.nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
   color: #ffffff;
   font-weight: 700;
   background-color: #212748;
}

.nav-pills .nav-link {
   background: 0 0;
   border: 0;
   border-radius: 0.375rem;
   -webkit-border-radius: 0.375rem;
   -moz-border-radius: 0.375rem;
   -ms-border-radius: 0.375rem;
   -o-border-radius: 0.375rem;
}


.tooltip-container {
   margin: 0 auto;
   display: inline-block;
}

/* EMPIEZA AQUÍ */

.tooltip-container {
   position: relative;
   cursor: pointer;
   padding: 2px 5px;
   margin: 0px 5px;
   background-color: #F2F2F2;
}

.tooltip-container:hover {
   color: #133154;
   background-color: #dcdcdc;
}

.tooltip-one {
   padding: 10px 20px;
   background: #000000bd;
   color: #fff;
   position: absolute;
   width: auto;
   border-radius: 5px;
   text-align: center;
   /* filter: drop-shadow(0 3px 5px #ccc); */
   line-height: 1.5;
   display: none;
   right: 10%;
   margin-top: 3px;
   margin-right: -43px;
   z-index: 1070;
}

/* .tooltip-one:after {
   content: "";
   position: absolute;
   bottom: -9px;
   left: 50%;
   margin-left: -9px;
   width: 18px;
   height: 18px;
   background: white;
   transform: rotate(45deg);
 } */

.tooltip-trigger:hover+.tooltip-one {
   display: block;

}

.icon-label {
   font-size: 18px;
   font-weight: 800;
}

.bg-view {
   background-color: #C9FFEB;
}

.scale-up-horizontal-left {
   animation: scale-up-horizontal-left 0.4s
}

@keyframes scale-up-horizontal-left {
   0% {
      transform: scaleX(.4);
      transform-origin: left center
   }

   100% {
      transform: scaleX(1);
      transform-origin: left center
   }
}