/* * Global Vars  #0025B8*/
/* 
 Title: Commnets Format BEM 
 * class" block-name "
 ? element
 Block: Name Block  
 # block-name block-name--modifier-name
 !fesgfrgre
 ~~degrehreh5ehj
 End:Fin 
 || BLOCK  
 Section
 /* TODO:Revisar clasede input oculto*/


 /* TODO:Revisar clasede input oculto*/
 :root {
    --fuenteh: 'PT Sans', sans-serif;
    --fuente-main: 'Mulish', sans-serif;
    --primary: #3ca93f;
    --secondary: #2686BC;
    --primario-light: #39a93d1a;
    --primario-dark: #12971b;
    --rojo: rgb(202, 50, 50);
    --amarillo: rgb(238, 192, 28);
    --verde: #318e2c;
    --verde-2: #3FD300;
    --azul: #2a95bf;
    --azul-2: #2a95bf3a;
    --blanco: #ffffff;
    --white: #ffffff;
    --gris: #f0f2f586;
    --gris-2: #808080;
    --gris-3: #c0c0c022;
    --negro: #000;
    --bg-primario: #000;
    /* --primary-vending: #00b89a; */
    --primary-vending: #0025B8;
    --border-1: 9px;
    --border-2: 12px;
    --border-3: 14px;
    --border-4: 30px;
    --text: #3c424d;
    --new-main-0: #ffae00;
    --new-main-6: #F2F2F2;
    --new-main-8: #B3B2B3;
    --new-frame: #f0f0f0;
 }
 
 
 /*  Section:Globals Utilies */
 /* Title: +++++++ Backgrounds +++++++ */
 
 .bg-brand-primary {
    /* background: #396afc;
    background: -webkit-linear-gradient(to bottom, #2948ff, #396afc);
    background: linear-gradient(to bottom, #2948ff, #396afc); */
 
    background: #055aaf;
    background: -webkit-linear-gradient(to top, #042dbf, #0575E6);
    background: linear-gradient(to top, #042dbf, #0575E6);
 
 }
 
 .bg-brand-smartpay {
    background: #2bbe3a;
    background: -webkit-linear-gradient(to top, #26a337, #2bbe3a);
    background: linear-gradient(to top, #26a337, #2bbe3a);
 }
 
 .bg-brand-night {
    background: #000428;
    background: -webkit-linear-gradient(to bottom, #004e92, #000428);
    background: linear-gradient(to bottom, #004e92, #000428);
 }
 
 .bg-brand-light-blue {
    background: #00c6ff;
    background: -webkit-linear-gradient(to top, #0072ff, #00c6ff);
    background: linear-gradient(to top, #0072ff, #00c6ff);
 }
 
 
 /* Blur */
 .bg-blur-sp {
    background-color: #29292946;
    backdrop-filter: blur(5px);
    /* border: 1px solid #e4e4e4a2; */
    /* box-shadow: 0px 0px 5px #222222; */
 }
 
 .bg-blur-sp-2 {

   background-color: rgba(177, 177, 177, 0.15);
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(5px);
    color: #fff;
   /* border: 1px solid #e4e4e4a2; */
   /* box-shadow: 0px 0px 5px #222222; */
}
 
 /* Utilits */
 ::-webkit-scrollbar {
    display: none;
 }
 
 .buttons-z {
    z-index: 9999;
 }
 
 /* Section: ++++++Init++++++ */
 /*Title: +++++++ Main Layouts +++++++  */
 
 .kiosk {
    display: flex;
    height: 100vh;
    display: flex;
    justify-content: center;
 
    /* border: 1px solid #a93c3c;
    background-color: var(--new-frame); */
 }
 
 
 .kiosk-container {
    background-color: #ffffff;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    /* align-content: flex-start; */
 }
 
 .kiosk-container_section {
    /* flex:3 1 auto;  */
    width: 100%;
    order: 0;
    flex-grow: 1;
    overflow: auto;
 }
 
 .kiosk-container_section_lista {
    padding: 120px 10px;
    /* border: 2px solid red; */
    overflow-x: auto;
 }
 
 .kiosk-lista {
    display: flex;
    height: 100%;
    width: 80px;
 }
 
 .kiosk-lista_items {
    cursor: pointer;
    position: relative;
    top: 0;
    transition: top ease 0.4s;
    -webkit-transition: top ease 0.4s;
    -moz-transition: top ease 0.4s;
    -ms-transition: top ease 0.4s;
    -o-transition: top ease 0.4s;
 }
 
 .kiosk-container_section_title {
    font-size: 50px;
 }
 
 /*~ Cards */
 
 .kiosk-card-item {
    width: 20rem;
    height: 530px;
    border-radius: 30px;
    -webkit-border-radius: 30px;
    -moz-border-radius: 30px;
    -ms-border-radius: 30px;
    -o-border-radius: 30px;
 }
 
 /* .kiosk-card-item:last-child {
    padding-right: 20px;
    border: 6px solid red; 
  } */
 
 
 .card-img-top {
    width: 100%;
    height: 100%;
    border-radius: 30px 30px 0px 0px;
    -webkit-border-radius: 30px 30px 0px 0px;
    -moz-border-radius: 30px 30px 0px 0px;
    -ms-border-radius: 30px 30px 0px 0px;
    -o-border-radius: 30px 30px 0px 0px;
 }
 
 .card-img-top>img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 30px 30px 0px 0px;
    -webkit-border-radius: 30px 30px 0px 0px;
    -moz-border-radius: 30px 30px 0px 0px;
    -ms-border-radius: 30px 30px 0px 0px;
    -o-border-radius: 30px 30px 0px 0px;
 }
 
 /* End: +++++ Init  +++++ */
 /* Section: +++++ Delivery  +++++ */
 .delivery {
    margin-top: 20px;
 }
 
 .kiosk-delivery {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: flex-start;
    gap: 1px;
 }
 
 
 .kiosk-delivery_header {
    padding: 3px;
    margin: 50px 10px;
    display: flex;
    justify-content: center;
    /* border: 2px solid #3c5ba9; */
    border-radius: 30px;
    -webkit-border-radius: 30px;
    -moz-border-radius: 30px;
    -ms-border-radius: 30px;
    -o-border-radius: 30px;
 }
 
 .kiosk-delivery_header_img {
    width: 250px;
    height: 120px;
    object-fit: cover;
    /* filter: drop-shadow(0 2px 5px rgba(158, 158, 158, 0.7)); */
 }
 
 .kiosk-delivery_title {
    font-size: 5rem;
    font-weight: 800;
    margin: 140px 10px 0px 0px;
 }
 
 .kiosk-header-img-categories {
    width: 300px;
    filter: drop-shadow(2px 2px 0px rgb(255, 255, 255));
    -webkit-filter: drop-shadow(2px 2px 0px rgb(255, 255, 255));
 }
 
 .kiosk-delivery_btn {
    margin-top: 150px;
    display: flex;
    justify-content: center;
 }
 
 .kiosk-delivery_btns>button {
    margin: 20px;
    border-radius: 60px;
    -webkit-border-radius: 60px;
    -moz-border-radius: 60px;
    -ms-border-radius: 60px;
    -o-border-radius: 60px;
 }
 
 .kiosk-text-card {
    font-size: 3.5rem;
    font-weight: 700;
 }
 
 .kiosk-text-categories {
    font-size: 2.7rem;
    font-weight: 700;
 }
 
 .kiosk-text-delivery {
    font-size: 3.5rem;
    font-weight: 700;
 }
 
 
 .btn-img-delivery {
    /* width:215px;  */
    font-size: 300px;
 }
 
 .btn-card-delivery {
    color: #0D6EFD;
    padding: 10rem 70px;
 
 }
 
 
 /* End: +++++ Delivery  +++++ */
 
 /* Section: Cateogires */
 
 /* Btns */
 
 .btn-cart {
    fill: #fff;
    cursor: pointer;
    /* background-color: var(--primary-vending); */
    padding: 36px;
    width: 160px;
    height: 160px;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
 }
 
 .kiosk-count {
    font-size: 3rem;
    top: -12px;
    left: -54px;
 }
 
 /* *Navigation Carrusel Buttons  */
 .nav-button {
    font-size: 5rem;
    transform: translateY(-50%);
    background-color: rgba(255, 255, 255, .15);
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(5px);
    color: #fff;
    padding: 5px 24px;
    cursor: pointer;
 }
 
 .nav-button:hover {
    background: #67B26F;
    background: -webkit-linear-gradient(to top, #4ca2cd, #67B26F);
    background: linear-gradient(to top, #4ca2cd, #67B26F);
 }
 
 .nav-button:focus {
    background-color: var(--primary-vending);
    border: 3px solid var(--primary-vending);
 }
 
 .nav-button:first-child {
    left: 0;
 }
 
 .nav-button:last-child {
    right: 0;
 }
 
 /* ~ Buttons Exits */
 .button-ft_0 {
    fill: var(--blanco);
    cursor: pointer;
    background-color: var(--primario);
    width: 150px;
    height: 150px;
    padding: 20px 15px 20px 5px;
    border-radius: 0% 100% 0% 100% / 100% 100% 0% 0%;
    -webkit-border-radius: 0% 100% 0% 100% / 100% 100% 0% 0%;
    -moz-border-radius: 0% 100% 0% 100% / 100% 100% 0% 0%;
    -ms-border-radius: 0% 100% 0% 100% / 100% 100% 0% 0%;
    -o-border-radius: 0% 100% 0% 100% / 100% 100% 0% 0%;
 }
 
 
 .button-ft_1 {
    fill: var(--blanco);
    cursor: pointer;
    background-color: var(--primary-vending);
    width: 150px;
    height: 150px;
    padding: 5px;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
 }
 
 
 .button-ft,
 .button-ft_0:active,
 .button-ft_1:active {
    transform: scale(.92);
 }
 
 .container-button {
    margin: 0px 0px 5px 4px !important;
 }
 
 /* End: Categories */
 
 /* Section: Product */
 
 .kiosk-text-product {
    font-size: 2.7rem;
    font-weight: 700;
 }
 
 .kiosk-text-card-product {
    font-size: 2.7rem;
 }
 
 /*~ lIST CATEGORIES  */
 .container-list-kiosk {
    height: 180px;
    padding: 2px 0px;
 }
 
 .container-list-kiosk_section {
    overflow: scroll;
    overflow-x: auto;
    white-space: nowrap;
    position: relative;
 }
 
 
 .container-list-kiosk::-webkit-scrollbar {
    width: 2px !important;
 }
 
 .container-list-kiosk ul {
    list-style: none;
    margin: 10px;
    padding: 0;
 }
 
 .container-list-kiosk li {
    display: inline-block;
    margin: 1px 9px;
 }
 
 .container-list-kiosk label {
    width: auto;
    font-size: 4.2rem;
 }
 
 .button-container-kk {
    display: inline-block;
    vertical-align: top;
    margin-right: 5px;
 }
 
 .button-container-kk img {
    width: 80px;
    height: 80px;
    margin: 5px;
 }
 
 
 /* Aplica un margen de 10px a todos los elementos de la lista */
 .kiosk-card-item_product {
    margin: 0px;
 }
 
 /* Aplica un margen izquierdo de 20px a los elementos mayores que el tercer hijo */
 .kiosk-card-item_product:nth-child(n + 4) {
    margin-top: 20px;
 }
 
 
 /* End: +++++Products+++++ */
 
 /* Section: Information Products */
 
 
 .kiosk-segments-container {
    margin-top: 1px !important;
 
 }
 
 .kiosk-segments-backgound {
    position: relative;
    width: 100%;
    height: 625px;
    box-shadow: rgba(9, 30, 66, 0.25) 0px 1px 1px, rgba(9, 30, 66, 0.13) 0px 0px 1px 1px;
    border-radius: 0 0 20px 20px;
    -webkit-border-radius: 0 0 20px 20px;
    -moz-border-radius: 0 0 20px 20px;
    -ms-border-radius: 0 0 20px 20px;
    -o-border-radius: 0 0 20px 20px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
 }
 
 .product-card-tv {
    border: 0px solid #000;
    width: 100%;
    height: 400px;
    border-radius: 0 0 20px 20px;
    -webkit-border-radius: 0 0 20px 20px;
    -moz-border-radius: 0 0 20px 20px;
    -ms-border-radius: 0 0 20px 20px;
    -o-border-radius: 0 0 20px 20px;
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgb(0, 0, 0) 100%);
    position: absolute;
    bottom: 0;
 }
 
 .kiosk-description-product {
 
    font-size: 2.5rem;
    font-weight: 700;
 }
 
 /* ~List Crad Option Segments */
 .kiosk-segments-items {
    border: #dfdfdf 1px solid;
    border-radius: 20px 20px 0 0;
    -webkit-border-radius: 20px 20px 0 0;
    -moz-border-radius: 20px 20px 0 0;
    -ms-border-radius: 20px 20px 0 0;
    -o-border-radius: 20px 20px 0 0;
 }
 
 label.radio-card {
    cursor: pointer;
 }
 
 label.radio-card .card-content-wrapper {
    scale: 1;
    background: #ffffff;
    border: solid 2px #e3e3e3;
    border-radius: 9px;
    width: 280px;
    height: 460px;
    padding: 5px;
    position: relative;
    display: grid;
    box-shadow: 0 2px 4px 0 rgba(219, 215, 215, 0.04);
    transition: 200ms linear;
    -webkit-border-radius: 9px;
    -moz-border-radius: 9px;
    -ms-border-radius: 9px;
    -o-border-radius: 9px;
 }
 
 label.radio-card .check-icon {
    width: 10px;
    height: 10px;
    display: flex;
    border: solid 2px #e3e3e3;
    border-radius: 50%;
    transition: 200ms linear;
    position: relative;
    justify-content: flex-end;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
 }
 
 label.radio-card .check-icon:before {
    content: "";
 
    /* background-image: url("data:image/svg+xml,%3Csvg width='12' height='9' viewBox='0 0 12 9' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0.93552 4.58423C0.890286 4.53718 0.854262 4.48209 0.829309 4.42179C0.779553 4.28741 0.779553 4.13965 0.829309 4.00527C0.853759 3.94471 0.889842 3.88952 0.93552 3.84283L1.68941 3.12018C1.73378 3.06821 1.7893 3.02692 1.85185 2.99939C1.91206 2.97215 1.97736 2.95796 2.04345 2.95774C2.11507 2.95635 2.18613 2.97056 2.2517 2.99939C2.31652 3.02822 2.3752 3.06922 2.42456 3.12018L4.69872 5.39851L9.58026 0.516971C9.62828 0.466328 9.68554 0.42533 9.74895 0.396182C9.81468 0.367844 9.88563 0.353653 9.95721 0.354531C10.0244 0.354903 10.0907 0.369582 10.1517 0.397592C10.2128 0.425602 10.2672 0.466298 10.3112 0.516971L11.0651 1.25003C11.1108 1.29672 11.1469 1.35191 11.1713 1.41247C11.2211 1.54686 11.2211 1.69461 11.1713 1.82899C11.1464 1.88929 11.1104 1.94439 11.0651 1.99143L5.06525 7.96007C5.02054 8.0122 4.96514 8.0541 4.90281 8.08294C4.76944 8.13802 4.61967 8.13802 4.4863 8.08294C4.42397 8.0541 4.36857 8.0122 4.32386 7.96007L0.93552 4.58423Z' fill='white'/%3E%3C/svg%3E%0A");
    background-repeat: no-repeat;
    background-size: 7px;
    background-position: center center;
    transform: scale(1.6);
    transition: 200ms linear; */
    opacity: 0;
 }
 
 label.radio-card input[type=radio] {
    visibility: hidden;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
 }
 
 label.radio-card input[type=radio]:checked+.card-content-wrapper {
    border: 3px solid #0045f4;
    color: #000000;
    background-color: #0048ff15;
    /*background: #055aaf;
     */
    /*background: -webkit-linear-gradient(to bottom, #042dbf, #0575E6);
     background: linear-gradient(to bottom, #042dbf, #0575E6);
     */
 
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10x;
    -ms-border-radius: 10x;
    -o-border-radius: 10x;
 }
 
 label.radio-card input[type=radio]:checked+.card-content-wrapper .check-icon {
    background: #3057d5;
    border-color: #3056d5c5;
    transform: scale(1.2);
 }
 
 label.radio-card input[type=radio]:checked+.card-content-wrapper .check-icon:before {
    transform: scale(1);
    opacity: 1;
 }
 
 label.radio-card input[type=radio]:focus+.card-content-wrapper .check-icon {
    box-shadow: 0 0 0 4px rgba(48, 86, 213, 0.2);
    border-color: #3056d5;
 }
 
 label.radio-card .card-content img {
    width: 100%;
    margin-bottom: 10px;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
    scale: 1;
 }
 
 label.radio-card .card-content h4 {
    width: 239px;
    font-size: 30px;
    letter-spacing: -0.24px;
    text-align: center;
    font-weight: 500;
    color: #000;
    margin-bottom: 10px;
    font-weight: 800;
 }
 
 label.radio-card .card-content h5 {
    font-size: 50px;
    line-height: 1.4;
    text-align: center;
    color: #22a009;
    font-weight: 800;
 }
 
 .kiosk-lista-container-product {
    padding: 15px 30px;
    /* border: 2px solid red; */
    overflow-x: auto;
 }
 
 
 .container-card-content-wrapper {
    margin-bottom: 2px;
 
 }
 
 .container-card-content-wrapper:last-child {
    margin-bottom: 180px;
 }
 
 /* End: Information Products */
 
 /* Section: Order Sumary */
 
 /* ~ Order Summary */
 .title-order-section {
    font-size: 5rem;
    font-weight: 800;
 }
 
 .button-discont {
    font-size: 2.8rem
 }
 
 .order-item {
    margin: 10px 10px;
 }
 
 .order-item:first-child {
    margin: 40px 10px;
 }
 
 .img-order-summary {
    width: 270px;
    height: 270px;
    object-fit: cover;
 }
 
 .text-count-orders {
    font-size: 2.5rem;
 }
 
 .image-empty {
    width: 300px;
    height: 300px;
 }
 
 .text-msg-empty-orders {
    font-size: 40px;
 }
 
 .title-product-text {
    font-size: 3.5rem;
 }
 
 .summary-text {
    font-size: 1.9rem;
 
 }
 
 .detail-text {
    font-size: 1.6rem;
 }
 
 .btn-dash {
    background-color: red;
 }
 
 .btn-plus {
    background-color: green;
 }
 
 .btn-dash:active {
    transform: scale(.85);
    -webkit-transform: scale(.85);
    -moz-transform: scale(.85);
    -ms-transform: scale(.85);
    -o-transform: scale(.85);
 }
 
 .btn-plus:active {
    transform: scale(.85);
    -webkit-transform: scale(.85);
    -moz-transform: scale(.85);
    -ms-transform: scale(.85);
    -o-transform: scale(.85);
 }
 
 .icon-delete-kk {
    font-size: 20px;
 }
 
 .count-items {
    font-size: 60px;
 }
 
 
 .dash-icon,
 .plus-icon {
    color: white;
    font-size: 60px;
    font-weight: 800;
    padding: 10px 20px;
 
    border-radius: 20px;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    -ms-border-radius: 20px;
    -o-border-radius: 20px;
 }
 
 .btn-add-order {
    font-size: 3.5rem;
    font-weight: 700;
    padding: 8px;
 }
 
 .btn-cancel-order {
    font-size: 4rem;
    font-weight: 700;
    padding: 12px;
 }
 
 .btn-send-order {
    font-size: 4rem;
    font-weight: 700;
    padding: 12px;
 }
 
 /* Totals */
 .text-subtitel-order {
    font-size: 2.8rem;
 }
 
 .total-container {
    border-top: 1px dashed #80808042;
 }
 
 .text-total-order {
    padding: 10px 0px;
    font-size: 3.5rem;
 }
 
 /* ~Modal Discounts */
 
 .kiosk-img-discount {
    width: 50%;
    height: 50%;
 }
 
 .kiosk-text-discount {
    font-size: 40px;
    font-weight: 800;
    margin: 60px 0px 0px 0px;
 }
 
 /* Title:+++++++ Borders +++++++ */
 .kiosk-border-4 {
    border-radius: var(--border-4);
    -webkit-border-radius: var(--border-4);
    -moz-border-radius: var(--border-4);
    -ms-border-radius: var(--border-4);
    -o-border-radius: var(--border-4);
 }
 
 .scroll-fast {
    scroll-behavior: smooth;
 }
 
 .kiosk-text-title-lang {
    font-size: 3rem;
    font-weight: 800;
    margin: 0px 0px 35px 0px;
 }
 
 .input-sp-qr {
    top: -30px;
    visibility: hidden;
 }
 
 .kiosk-container-main {
    color: transparent;
 }
 
 /* !End Global **/
 
 /* || Sections || Steps **/
 
 
 /*~ +++++ Buttons ++++++ */
 
 
 
 
 
 
 
 
 
 /* ~ Others Styles  */
 
 .key_1 {
    background-color: #eaeaeaa4;
    border: 2px solid var(--new-main-8);
    border-radius: 30px;
    /* width: 5rem;
    font-size: 3rem;
    margin: 5px; */
 }
 
 
 .kiosk-key-letter {
    font-size: 2.5rem;
    font-weight: 800;
    padding: 30px;
    border: 2px solid var(--new-main-8);
    border-radius: 30px;
    -webkit-border-radius: 30px;
    -moz-border-radius: 30px;
    -ms-border-radius: 30px;
    -o-border-radius: 30px;
    margin: 2px;
 }
 
 
 
 .kiosk-key-letter:active {
    transform: scale(0.95);
    -webkit-transform: scale(0.95);
    -moz-transform: scale(0.95);
    -ms-transform: scale(0.95);
    -o-transform: scale(0.95);
 }
 
 .kiosk-key-letter:focus {
    color: var(--blanco);
    background-color: var(--primary-vending);
    transform: scale(0.95);
    -webkit-transform: scale(0.95);
    -moz-transform: scale(0.95);
    -ms-transform: scale(0.95);
    -o-transform: scale(0.95);
 }
 
 .keypadPrimary-input_1 {
    color: var(--primary-vending);
    width: 100%;
    margin: 2.5rem 0;
    padding: 15px 0;
    font-size: 5.5rem;
    font-weight: 700;
    text-align: center;
    border: 2px solid rgb(226, 226, 226);
    letter-spacing: 25px;
 }
 
 
 .modal-content {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100% !important;
    color: var(--bs-modal-color);
    pointer-events: auto;
    background-color: var(--bs-modal-bg);
    background-clip: padding-box;
    border: var(--bs-modal-border-width) solid var(--bs-modal-border-color);
    border-radius: 60px;
    outline: 0;
    -webkit-border-radius: 60px;
    -moz-border-radius: 60px;
    -ms-border-radius: 60px;
    -o-border-radius: 60px;
 }
 
 /* 
 @media (min-width: 576px) {
    .modal-dialog-kk {
       max-width: 790px;
       margin-right: auto;
       margin-left: auto;
    }
 
 }  */
 
 
 .modal-footer-sp_0 {
    display: flex;
    flex-shrink: 0;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-end;
    padding: calc(var(--bs-modal-padding) - var(--bs-modal-footer-gap) * .5);
    background-color: var(--bs-modal-footer-bg);
    border-top: 0 0 !important;
    border-bottom-right-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
 }
 
 .modal-footer-sp_1 {
    margin: 10px;
    display: flex;
    flex-shrink: 0;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-end;
    padding: calc(var(--bs-modal-padding) - var(--bs-modal-footer-gap) * .5);
    background-color: var(--bs-modal-footer-bg);
    border-top: 0 0 !important;
    border-bottom-right-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
 }
 
 /* ! -----End Delivery----- */
 /* **************************/
 /* ? ------Modal Languaje------ */
 .btn-exit_0 {
    fill: var(--white);
    cursor: pointer;
    background-color: var(--primario);
    width: 120px;
    height: 120px;
    padding: 20px 15px 20px 5px;
    border-radius: 0% 100% 55% 45% / 57% 100% 0% 43%;
    -webkit-border-radius: 0% 100% 55% 45% / 57% 100% 0% 43%;
    -moz-border-radius: 0% 100% 55% 45% / 57% 100% 0% 43%;
    -ms-border-radius: 0% 100% 55% 45% / 57% 100% 0% 43%;
    -o-border-radius: 0% 100% 55% 45% / 57% 100% 0% 43%;
 }
 
 .btn-exit_1 {
    fill: var(--white);
    cursor: pointer;
    background-color: var(--primario);
    width: 120px;
    height: 120px;
    padding: 5px;
    margin-left: 40px;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
 }
 
 
 .btn-exit_0:hover {
    transform: scale(.92);
    background-color: var(--primario-dark);
    border: 10px solid #000;
 }
 
 .btn-exit_0:active {
    transform: scale(.92);
    border: 10px solid #000;
 }
 
 .btn-exit_1:active {
    transform: scale(.92);
    border: 10px solid #000;
 }
 
 /* ! ----End Languaje---- */
 /* **************************/
 
 
 /* **************************/
 /* ? ------Categories------ */
 
 /* Title: Carrusel Promotions */
 
 
 
 .kiosk-lista-container-product {
    padding: 15px 30px;
    /* border: 2px solid red; */
    overflow-x: auto;
 }
 
 
 
 .kiosk-lista-container::-webkit-scrollbar {
    display: none;
 }
 
 
 
 
 
 .kiosk-categories>div {
    /* margin: 15px 1px;
    padding: 8px; */
    border: 0px solid red;
 }
 
 /* .carousel-container {
    position: relative;
    overflow: hidden;
    width: 100%;
    margin: 0 auto;
 } */
 
 .carousel-container {
    overflow: hidden;
    width: 100%;
    margin: 0;
 }
 
 .carousel-item {
    display: none;
 }
 
 .carousel-item img {
    width: 100%;
    height: 80vh;
    /* background-size: contain; */
    object-fit: cover;
    border: 0px solid var(--new-main-8);
    /* border-radius: 30px;
    -webkit-border-radius: 30px;
    -moz-border-radius: 30px;
    -ms-border-radius: 30px;
    -o-border-radius: 30px; */
 }
 
 .active {
    display: block;
 }
 
 .btn-direction {
    top: 50%;
 }
 
 
 
 
 
 /* ! ----End Categories---- */
 /* **************************/
 /* ? -------Products------- */
 /* .btn-exit-footer {
    position: absolute;
    bottom: 0;
    fill: var(--white);
    cursor: pointer;
    background-color: var(--primary-vending);
    padding: 20px 34px 0px 10px;
    width: 160px;
    height: 160px;
    border-radius: 0% 100% 0% 100% / 100% 100% 0% 0%;
    -webkit-border-radius: 0% 100% 0% 100% / 100% 100% 0% 0%;
    -moz-border-radius: 0% 100% 0% 100% / 100% 100% 0% 0%;
    -ms-border-radius: 0% 100% 0% 100% / 100% 100% 0% 0%;
    -o-border-radius: 0% 100% 0% 100% / 100% 100% 0% 0%;
 
 
 
 } */
 
 
 .btn-exit-footer {
    position: absolute;
    bottom: 0;
    fill: var(--white);
    cursor: pointer;
    background-color: var(--primary-vending);
    padding: 20px 34px 0px 10px;
    /* width: 160px;
    height: 160px; */
    border-radius: 0% 100% 0% 100% / 100% 100% 0% 0%;
    -webkit-border-radius: 0% 100% 0% 100% / 100% 100% 0% 0%;
    -moz-border-radius: 0% 100% 0% 100% / 100% 100% 0% 0%;
    -ms-border-radius: 0% 100% 0% 100% / 100% 100% 0% 0%;
    -o-border-radius: 0% 100% 0% 100% / 100% 100% 0% 0%;
 
 }
 
 
 
 
 .btn-exit-footer:active {
    transform: scale(.92);
    border: 10px solid #000;
 }
 
 .button-ps {
    margin-bottom: 150px;
 }
 
 .bg-transparent-sp {
    background-color: transparent;
 }
 
 
 
 
 
 
 
 
 /* ! -----End Products----- */
 /* **************************/
 /* ? ----Info Products ---- */
 /* ! --End Info Products--- */
 /* **************************/
 /* ? ----Order Summary----- */
 /* ! ----Order Summary----- */
 /* **************************/
 /* ? -------Payments------- */
 /* ! -------Payments------- */
 /* **************************/
 /* ? ----Status Payment---- */
 /* ! ----Status Payment---- */
 /* **************************/
 /* ? ----End Transaction--- */
 /* ! ----End Transaction--- */
 
 .kiosk-subtitle {
    font-size: 3.5rem;
    font-weight: 800;
 }
 
 .kiosk-subtitle-md {
    font-size: 2.5rem;
    font-weight: 800;
 
 }
 
 .kiosk-subtitle-item {
    font-size: 2.5rem;
    font-weight: 800;
    width: 480px;
 }
 
 .kiosk-subtitle-s {
    font-size: 2rem;
    font-weight: 500;
 }
 
 /* Text Floating */
 .kiosk-button {
    position: fixed;
    top: 0px;
    right: 0px;
 }
 
 
 
 
 
 .btn-img-categorie {
    width: 300px;
 
 }
 
 
 .btn-return-kiosk-container {
    position: fixed;
    top: 0;
    left: 0;
    padding: 15px;
 }
 
 .btn-return-kiosk {
    background-color: #1479de;
    color: #ecf0f1;
    border: none;
    padding: 10px 35px;
    font-size: 3.2rem;
    font-weight: 700;
    cursor: pointer;
    border-radius: 0 40px 40px 0;
    -webkit-border-radius: 0 40px 40px 0;
    -moz-border-radius: 0 40px 40px 0;
    -ms-border-radius: 0 40px 40px 0;
    -o-border-radius: 0 40px 40px 0;
 }
 
 .btn-return-kiosk:hover {
    background-color: #1271ca;
 }
 
 
 .kiosk-title-segments {
    font-size: 30px;
    font-weight: 700;
 }
 
 .kiosk-price-segments {
    font-size: 30px;
    font-weight: 800;
 }
 
 .kiosk-img-segments {
    width: 250px;
    object-fit: cover;
    border-radius: 30px !important;
    -webkit-border-radius: 30px !important;
    -moz-border-radius: 30px !important;
    -ms-border-radius: 30px !important;
    -o-border-radius: 30px !important;
 }
 
 
 
 .kiosk-banner-promotion {
    padding: 120px;
    border-radius: 30px;
    -webkit-border-radius: 30px;
    -moz-border-radius: 30px;
    -ms-border-radius: 30px;
    -o-border-radius: 30px;
    -webkit-border-radius: 30px;
 }
 
 .list-group {
    overflow: hidden;
    width: 100%;
    max-width: 100%;
    margin-inline: 1.5rem;
 }
 
 .form-check-input:checked+.form-checked-content {
    opacity: .5;
 }
 
 .form-check-input-placeholder {
    border-style: dashed;
 }
 
 [contenteditable]:focus {
    outline: 0;
 }
 
 .list-group-checkable .list-group-item {
    cursor: pointer;
 }
 
 .list-group-item-check {
    position: absolute;
    clip: rect(0, 0, 0, 0);
 }
 
 .list-group-item-check:hover+.list-group-item {
    background-color: var(--bs-secondary-bg);
 }
 
 .list-group-item-check:checked+.list-group-item {
    color: #fff;
    background-color: var(--bs-primary);
    border-color: var(--bs-primary);
 }
 
 .list-group-item-check[disabled]+.list-group-item,
 .list-group-item-check:disabled+.list-group-item {
    pointer-events: none;
    filter: none;
    opacity: .5;
 }
 
 .list-group-radio .list-group-item {
    cursor: pointer;
    border-radius: .5rem;
 }
 
 .list-group-radio .form-check-input {
    z-index: 2;
    margin-top: -.5em;
 }
 
 
 
 .list-group-radio .form-check-input:checked+.list-group-item {
    background-color: var(--bs-body);
    border-color: var(--bs-primary);
    box-shadow: 0 0 0 2px var(--bs-primary);
 }
 
 .list-group-radio .form-check-input[disabled]+.list-group-item,
 .list-group-radio .form-check-input:disabled+.list-group-item {
    pointer-events: none;
    filter: none;
    opacity: .5;
 }
 
 
 .kiosk-delivery-footer {
    display: flex;
    justify-content: space-between;
 }
 
 .round-kiosk-button {
    width: 4rem;
    height: 4rem;
    border-radius: 50%;
    background-color: var(--primario);
    font-size: 44px;
    text-align: center;
    line-height: 50px;
 }
 
 .count-kiosk {
    font-size: 3rem;
 }
 
 .count-kiosk-os {
    font-size: 3.5rem;
 }
 
 .round-kiosk-button:active {
    transform: scale(.85);
    -webkit-transform: scale(.85);
    -moz-transform: scale(.85);
    -ms-transform: scale(.85);
    -o-transform: scale(.85);
 }
 
 /* Animations */
 .scale-up-top {
    animation: scale-up-top 0.4s;
 }
 
 @keyframes scale-up-top {
    0% {
       transform: scale(.5);
       transform-origin: center top
    }
 
    100% {
       transform: scale(1);
       transform-origin: center top
    }
 }
 
 
 .scale-up-horizontal-left {
    animation: scale-up-horizontal-left 0.4s;
    -webkit-animation: scale-up-horizontal-left 0.4s;
 }
 
 @keyframes scale-up-horizontal-left {
    0% {
       transform: scaleX(.4);
       transform-origin: left center;
       -webkit-transform: scaleX(.4);
       -moz-transform: scaleX(.4);
       -ms-transform: scaleX(.4);
       -o-transform: scaleX(.4);
    }
 
    100% {
       transform: scaleX(1);
       transform-origin: left center
    }
 }
 
 
 
 
 @media screen and (min-width: 1080px) and (min-height: 1920px) {
    .round-kiosk-button {
       width: 4rem;
       height: 4rem;
       border-radius: 50%;
       background-color: var(--primario);
       color: rgb(159, 13, 13);
       font-size: 44px;
       text-align: center;
       line-height: 50px;
    }
 
 }
 
 
 
 
 
 
 .radio-tile-group {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
 }
 
 
 .radio-tile-group .input-container {
    position: relative;
    width: 100%;
    height: 100%;
    margin: 0.5rem;
 }
 
 .radio-tile-group .input-container .radio-button {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    margin: 0;
    cursor: pointer;
 }
 
 .radio-tile-group .input-container .radio-tile {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    /* background: #fff; */
    border: 5px solid #e8e8e8;
    border-radius: 30px;
    padding: 8rem 1rem;
    transition: transform 300ms ease;
    -webkit-border-radius: 30px;
    -moz-border-radius: 30px;
    -ms-border-radius: 30px;
    -o-border-radius: 30px;
 }
 
 
 .radio-tile-group .input-container .icon-pay {
    fill: #079ad9;
    width: 3rem;
    height: 3rem;
 }
 
 .radio-tile-group .input-container .radio-tile-label {
    text-align: center;
    font-size: 2.2rem;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 1px;
 
 }
 
 .radio-tile-group .input-container .radio-button:checked+.radio-tile {
    background-color: #079ad9;
    border: 2px solid #079ad9;
    color: white;
    transform: scale(1.1, 1.1);
    font-weight: 800;
 }
 
 .radio-tile-group .input-container .radio-button:checked+.radio-tile .icon-pay {
    fill: white;
    background-color: #079ad9;
    font-weight: 800;
 }
 
 .icon-pay {
    width: 170px;
 }
 
 .img-pay-met-kk {
    width: 170px;
    height: 170px;
    object-fit: contain;
 }
 
 .radio-tile-group .input-container .radio-button:checked+.radio-tile .radio-tile-label {
    color: white;
    /* background-color: #07c0d9; */
    font-weight: 800;
 }
 
 .radio-tile-group .input-container .radio-button:focus {
    border: 10px solid #fa1b1b;
    border-radius: 30px;
 }
 
 
 
 
 .icon-delete-kk {
    font-size: 60px;
 }
 
 .btn-warning-kk {
    border: #0025B8 dashed 3px;
    color: #0025B8;
 }
 
 .btn-warning-kk:hover {
    border: #0025B8 dashed 3px;
 
    color: #0025B8;
    background-color: #0025b820;
 }
 
 .btn-warning-kk:active {
    transform: scale(.85);
    -webkit-transform: scale(.85);
    -moz-transform: scale(.85);
    -ms-transform: scale(.85);
    -o-transform: scale(.85);
 }
 
 .kk-text-2 {
    font-size: 23px;
    font-weight: 700;
 }
 
 
 /* .scale-hover:active{
    transform: scale(.85);
    -webkit-transform: scale(.85);
    -moz-transform: scale(.85);
    -ms-transform: scale(.85);
    -o-transform: scale(.85);
 }*/
 
 
 
 .scale-hover-sm:focus {
    transform: scale(1.10) !important;
    -webkit-transform: scale(1.10) !important;
    -moz-transform: scale(1.10) !important;
    -ms-transform: scale(1.10) !important;
    -o-transform: scale(1.10) !important;
 }
 
 
 
 
 .icon-focus {
    width: 100px;
    height: auto;
    display: none;
 }
 
 .icon-focus:focus::before {
    opacity: 1;
 }
 
 .icon-focus:not(:focus)::before {
    display: none;
 }
 
 .postition-icon-sm {
    top: 65%;
    z-index: 999;
    left: 30%;
 }
 
 .postition-icon-md {
    bottom: -10%;
    z-index: 999;
    left: 37%;
 }
 
 .postition-icon-promotion {
    top: 55%;
    left: 10%;
 }
 
 .postition-icon-categories {
    bottom: -15%;
    right: 40%;
    z-index: 999;
 }
 
 .position-icon-segments {
    bottom: -21%;
    right: 40%;
    z-index: 999;
 
 }
 
 .postition-icon-count {
    bottom: -21%;
    right: 40%;
    z-index: 999;
 }
 
 .postition-icon-count {
    bottom: -70%;
    z-index: 999;
    left: -15%;
 }
 
 .postition-icon-product {
    bottom: 4%;
    left: 0%;
    z-index: 999;
 }
 
 .postition-icon-btn-accions {
    bottom: -10%;
    left: 80%;
    z-index: 999;
 }
 
 .postition-icon-key {
    bottom: -40%;
    left: 0%;
    z-index: 999;
 }
 
 
 .postition-icon-list {
    bottom: -40%;
    right: 0%;
    z-index: 999;
 }
 
 .postition-icon-dis {
    bottom: -75%;
    right: 0%;
    z-index: 999;
 }
 
 
 .postition-icon-pays {
    bottom: 10%;
    z-index: 999;
    left: 37%;
 }
 
 
 .focus-pay:focus {
    border: 10px solid rgb(240, 188, 18);
    border-radius: 30px;
 }
 
 .postition-icon-details {
    top: 0;
    left: 22%;
    z-index: 999;
 }
 
 .postition-icon-pays {
    bottom: -10%;
    z-index: 999;
    left: 5%;
 }
 
 .postition-icon-edit {
    top: 50%;
    z-index: 999;
    right: 50%;
 }
 
 details:focus {
    border: none;
 }
 
 
 /* !Revisar  */
 
 .list-group-item {
    background-color: var(--bs-light);
    border: 1px solid #cfcfcf73;
    border-radius: 1px;
    -webkit-border-radius: 1px;
    -moz-border-radius: 1px;
    -ms-border-radius: 1px;
    -o-border-radius: 1px;
 }
 
 .list-group-radio .list-group-item:hover,
 .list-group-radio .list-group-item:focus {
    color: var(--blanco) !important;
    background-color: var(--primary-vending) !important;
 }
 
 .list-group-item-check:hover+.list-group-item {
    background-color: var(--bs-light);
    border: 1px solid #007bff;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
 }
 
 .list-group-item-check:checked+.list-group-item {
    color: #007bff;
    ;
    background-color: var(--bs-light);
    border: 1px solid #007bff;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
 }
 
 
 .list-group-item-check[disabled]+.list-group-item,
 .list-group-item-check:disabled+.list-group-item {
    pointer-events: none;
    filter: none;
    opacity: .5;
 }
 
 
 /* Animation */
 
 .scale-up-left {
    animation: scale-up-left 0.4s;
 }
 
 @keyframes scale-up-left {
    0% {
       transform: scale(.5);
       transform-origin: left center
    }
 
    100% {
       transform: scale(1);
       transform-origin: left center
    }
 }
 
 
 
 .bounce-left {
    animation: bounce-left 1.2s linear both;
    transition: 0.5s ease-in
 }
 
 @keyframes bounce-left {
    0% {
       transform: translateX(-48px);
       animation-timing-function: ease-in;
       opacity: 1
    }
 
    24% {
       opacity: 1
    }
 
    40% {
       transform: translateX(-26px);
       animation-timing-function: ease-in
    }
 
    65% {
       transform: translateX(-13px);
       animation-timing-function: ease-in
    }
 
    82% {
       transform: translateX(-6.5px);
       animation-timing-function: ease-in
    }
 
    93% {
       transform: translateX(-4px);
       animation-timing-function: ease-in
    }
 
    25%,
    55%,
    75%,
    87%,
    98% {
       transform: translateX(0);
       animation-timing-function: ease-out
    }
 
    100% {
       transform: translateX(0);
       animation-timing-function: ease-out;
       opacity: 1
    }
 }
 
 .msg-pay {
    margin: 25rem 0;
 }
 
 .msg-pay-bottom {
    margin: 10rem 0;
 }
 
 .msg-position {
    top: 10%;
 
 }
 
 .display-5-kk {
 
    font-size: calc(1.425rem + 2.1vw);
    font-weight: 300;
    line-height: 1.2;
 
 }
 
 
 
 .kiosk-container-main {
    color: transparent;
 }
 
 /* !End Global **/
 
 /* || Sections || Steps **/
 /* Title: Delivery------- */
 /* Title: General Styles  */
 
 .kiosk-delivery_header {
    padding: 3px;
    margin: 50px 0px;
    display: flex;
    justify-content: center;
    /* border: 2px solid #3c5ba9; */
    border-radius: 30px;
    -webkit-border-radius: 30px;
    -moz-border-radius: 30px;
    -ms-border-radius: 30px;
    -o-border-radius: 30px;
 }
 
 
 .kiosk-header-img-categories {
    width: 300px;
    filter: drop-shadow(2px 2px 0px rgb(255, 255, 255));
    -webkit-filter: drop-shadow(2px 2px 0px rgb(255, 255, 255));
 }
 
 
 
 
 
 
 
 
 
 
 
 /* ~ Others Styles  */
 
 .key_1 {
    background-color: #eaeaeaa4;
    border: 2px solid var(--new-main-8);
    border-radius: 30px;
    /* width: 5rem;
    font-size: 3rem;
    margin: 5px; */
 }
 
 
 .kiosk-key-letter {
    font-size: 2.5rem;
    font-weight: 800;
    padding: 30px;
    border: 2px solid var(--new-main-8);
    border-radius: 30px;
    -webkit-border-radius: 30px;
    -moz-border-radius: 30px;
    -ms-border-radius: 30px;
    -o-border-radius: 30px;
    margin: 2px;
 }
 
 
 
 .kiosk-key-letter:active {
    transform: scale(0.95);
    -webkit-transform: scale(0.95);
    -moz-transform: scale(0.95);
    -ms-transform: scale(0.95);
    -o-transform: scale(0.95);
 }
 
 .kiosk-key-letter:focus {
    color: var(--blanco);
    background-color: var(--primary-vending);
    transform: scale(0.95);
    -webkit-transform: scale(0.95);
    -moz-transform: scale(0.95);
    -ms-transform: scale(0.95);
    -o-transform: scale(0.95);
 }
 
 .keypadPrimary-input_1 {
    color: var(--primary-vending);
    width: 100%;
    margin: 2.5rem 0;
    padding: 15px 0;
    font-size: 5.5rem;
    font-weight: 700;
    text-align: center;
    border: 2px solid rgb(226, 226, 226);
    letter-spacing: 25px;
 }
 
 
 .modal-content {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100% !important;
    color: var(--bs-modal-color);
    pointer-events: auto;
    background-color: var(--bs-modal-bg);
    background-clip: padding-box;
    border: var(--bs-modal-border-width) solid var(--bs-modal-border-color);
    border-radius: 60px;
    outline: 0;
    -webkit-border-radius: 60px;
    -moz-border-radius: 60px;
    -ms-border-radius: 60px;
    -o-border-radius: 60px;
 }
 
 /* 
 @media (min-width: 576px) {
    .modal-dialog-kk {
       max-width: 790px;
       margin-right: auto;
       margin-left: auto;
    }
 
 }  */
 
 
 .modal-footer-sp_0 {
    display: flex;
    flex-shrink: 0;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-end;
    padding: calc(var(--bs-modal-padding) - var(--bs-modal-footer-gap) * .5);
    background-color: var(--bs-modal-footer-bg);
    border-top: 0 0 !important;
    border-bottom-right-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
 }
 
 .modal-footer-sp_1 {
    margin: 10px;
    display: flex;
    flex-shrink: 0;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-end;
    padding: calc(var(--bs-modal-padding) - var(--bs-modal-footer-gap) * .5);
    background-color: var(--bs-modal-footer-bg);
    border-top: 0 0 !important;
    border-bottom-right-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
 }
 
 /* ! -----End Delivery----- */
 /* **************************/
 /* ? ------Modal Languaje------ */
 .btn-exit_0 {
    fill: var(--white);
    cursor: pointer;
    background-color: var(--primario);
    width: 120px;
    height: 120px;
    padding: 20px 15px 20px 5px;
    border-radius: 0% 100% 55% 45% / 57% 100% 0% 43%;
    -webkit-border-radius: 0% 100% 55% 45% / 57% 100% 0% 43%;
    -moz-border-radius: 0% 100% 55% 45% / 57% 100% 0% 43%;
    -ms-border-radius: 0% 100% 55% 45% / 57% 100% 0% 43%;
    -o-border-radius: 0% 100% 55% 45% / 57% 100% 0% 43%;
 }
 
 .btn-exit_1 {
    fill: var(--white);
    cursor: pointer;
    background-color: var(--primario);
    width: 120px;
    height: 120px;
    padding: 5px;
    margin-left: 40px;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
 }
 
 
 .btn-exit_0:hover {
    transform: scale(.92);
    background-color: var(--primario-dark);
    border: 10px solid #000;
 }
 
 .btn-exit_0:active {
    transform: scale(.92);
    border: 10px solid #000;
 }
 
 .btn-exit_1:active {
    transform: scale(.92);
    border: 10px solid #000;
 }
 
 /* ! ----End Languaje---- */
 /* **************************/
 
 
 /* **************************/
 /* ? ------Categories------ */
 
 /* Title: Carrusel Promotions */
 
 .categories {
    bottom: 2%;
 
 }
 
 
 
 
 
 .kiosk-lista-container::-webkit-scrollbar {
    display: none;
 }
 
 
 
 .kiosk-categories {
    background-color: var(--blanco);
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: flex-start;
    gap: 1px;
 }
 
 .kiosk-categories>div {
    /* margin: 15px 1px;
    padding: 8px; */
    border: 0px solid red;
 }
 
 /* .carousel-container {
    position: relative;
    overflow: hidden;
    width: 100%;
    margin: 0 auto;
 } */
 
 .carousel-container {
    overflow: hidden;
    width: 100%;
    margin: 0;
 }
 
 .carousel-item {
    display: none;
 }
 
 .carousel-item img {
    width: 100%;
    height: 80vh;
    /* background-size: contain; */
    object-fit: cover;
    border: 0px solid var(--new-main-8);
    /* border-radius: 30px;
    -webkit-border-radius: 30px;
    -moz-border-radius: 30px;
    -ms-border-radius: 30px;
    -o-border-radius: 30px; */
 }
 
 .active {
    display: block;
 }
 
 .btn-direction {
    top: 50%;
 }
 
 
 
 
 /* ! ----End Categories---- */
 /* **************************/
 /* ? -------Products------- */
 /* .btn-exit-footer {
    position: absolute;
    bottom: 0;
    fill: var(--white);
    cursor: pointer;
    background-color: var(--primary-vending);
    padding: 20px 34px 0px 10px;
    width: 160px;
    height: 160px;
    border-radius: 0% 100% 0% 100% / 100% 100% 0% 0%;
    -webkit-border-radius: 0% 100% 0% 100% / 100% 100% 0% 0%;
    -moz-border-radius: 0% 100% 0% 100% / 100% 100% 0% 0%;
    -ms-border-radius: 0% 100% 0% 100% / 100% 100% 0% 0%;
    -o-border-radius: 0% 100% 0% 100% / 100% 100% 0% 0%;
 
 
 
 } */
 
 
 .btn-exit-footer {
    position: absolute;
    bottom: 0;
    fill: var(--white);
    cursor: pointer;
    background-color: var(--primary-vending);
    padding: 20px 34px 0px 10px;
    /* width: 160px;
    height: 160px; */
    border-radius: 0% 100% 0% 100% / 100% 100% 0% 0%;
    -webkit-border-radius: 0% 100% 0% 100% / 100% 100% 0% 0%;
    -moz-border-radius: 0% 100% 0% 100% / 100% 100% 0% 0%;
    -ms-border-radius: 0% 100% 0% 100% / 100% 100% 0% 0%;
    -o-border-radius: 0% 100% 0% 100% / 100% 100% 0% 0%;
 
 }
 
 
 
 .button-ft_0 {
    fill: var(--white);
    cursor: pointer;
    background-color: var(--primario);
    width: 150px;
    height: 150px;
    padding: 20px 15px 20px 5px;
    border-radius: 0% 100% 0% 100% / 100% 100% 0% 0%;
    -webkit-border-radius: 0% 100% 0% 100% / 100% 100% 0% 0%;
    -moz-border-radius: 0% 100% 0% 100% / 100% 100% 0% 0%;
    -ms-border-radius: 0% 100% 0% 100% / 100% 100% 0% 0%;
    -o-border-radius: 0% 100% 0% 100% / 100% 100% 0% 0%;
 }
 
 
 
 
 .btn-exit-footer:active {
    transform: scale(.92);
    border: 10px solid #000;
 }
 
 .button-ps {
    margin-bottom: 150px;
 }
 
 .bg-transparent-sp {
    background-color: transparent;
 }
 
 .kiosk-subtitle {
    font-size: 3.5rem;
    font-weight: 800;
 }
 
 .kiosk-subtitle-md {
    font-size: 2.5rem;
    font-weight: 800;
 
 }
 
 .kiosk-subtitle-item {
    font-size: 2.5rem;
    font-weight: 800;
    width: 480px;
 }
 
 .kiosk-subtitle-s {
    font-size: 2rem;
    font-weight: 500;
 }
 
 /* Text Floating */
 .kiosk-button {
    position: fixed;
    top: 0px;
    right: 0px;
 }
 
 
 
 
 
 .btn-img-categorie {
    width: 300px;
 
 }
 
 
 .btn-return-kiosk-container {
    position: fixed;
    top: 0;
    left: 0;
    padding: 15px;
 }
 
 .btn-return-kiosk {
    background-color: #1479de;
    color: #ecf0f1;
    border: none;
    padding: 10px 35px;
    font-size: 3.2rem;
    font-weight: 700;
    cursor: pointer;
    border-radius: 0 40px 40px 0;
    -webkit-border-radius: 0 40px 40px 0;
    -moz-border-radius: 0 40px 40px 0;
    -ms-border-radius: 0 40px 40px 0;
    -o-border-radius: 0 40px 40px 0;
 }
 
 .btn-return-kiosk:hover {
    background-color: #1271ca;
 }
 
 
 .kiosk-title-segments {
    font-size: 30px;
    font-weight: 700;
 }
 
 .kiosk-price-segments {
    font-size: 30px;
    font-weight: 800;
 }
 
 .kiosk-img-segments {
    width: 250px;
    object-fit: cover;
    border-radius: 30px !important;
    -webkit-border-radius: 30px !important;
    -moz-border-radius: 30px !important;
    -ms-border-radius: 30px !important;
    -o-border-radius: 30px !important;
 }
 
 .kiosk-banner-promotion {
    padding: 120px;
    border-radius: 30px;
    -webkit-border-radius: 30px;
    -moz-border-radius: 30px;
    -ms-border-radius: 30px;
    -o-border-radius: 30px;
    -webkit-border-radius: 30px;
 }
 
 .list-group {
    overflow: hidden;
    width: 100%;
    max-width: 100%;
    margin-inline: 1.5rem;
 }
 
 .form-check-input:checked+.form-checked-content {
    opacity: .5;
 }
 
 .form-check-input-placeholder {
    border-style: dashed;
 }
 
 [contenteditable]:focus {
    outline: 0;
 }
 
 .list-group-checkable .list-group-item {
    cursor: pointer;
 }
 
 .list-group-item-check {
    position: absolute;
    clip: rect(0, 0, 0, 0);
 }
 
 .list-group-item-check:hover+.list-group-item {
    background-color: var(--bs-secondary-bg);
 }
 
 .list-group-item-check:checked+.list-group-item {
    color: #fff;
    background-color: var(--bs-primary);
    border-color: var(--bs-primary);
 }
 
 .list-group-item-check[disabled]+.list-group-item,
 .list-group-item-check:disabled+.list-group-item {
    pointer-events: none;
    filter: none;
    opacity: .5;
 }
 
 .list-group-radio .list-group-item {
    cursor: pointer;
    border-radius: .5rem;
 }
 
 .list-group-radio .form-check-input {
    z-index: 2;
    margin-top: -.5em;
 }
 
 
 
 .list-group-radio .form-check-input:checked+.list-group-item {
    background-color: var(--bs-body);
    border-color: var(--bs-primary);
    box-shadow: 0 0 0 2px var(--bs-primary);
 }
 
 .list-group-radio .form-check-input[disabled]+.list-group-item,
 .list-group-radio .form-check-input:disabled+.list-group-item {
    pointer-events: none;
    filter: none;
    opacity: .5;
 }
 
 
 .kiosk-delivery-footer {
    display: flex;
    justify-content: space-between;
 }
 
 .round-kiosk-button {
    width: 4rem;
    height: 4rem;
    border-radius: 50%;
    background-color: var(--primario);
    font-size: 44px;
    text-align: center;
    line-height: 50px;
 }
 
 
 .round-kiosk-button:active {
    transform: scale(.85);
    -webkit-transform: scale(.85);
    -moz-transform: scale(.85);
    -ms-transform: scale(.85);
    -o-transform: scale(.85);
 }
 
 /* Animations */
 .scale-up-top {
    animation: scale-up-top 0.4s;
 }
 
 @keyframes scale-up-top {
    0% {
       transform: scale(.5);
       transform-origin: center top
    }
 
    100% {
       transform: scale(1);
       transform-origin: center top
    }
 }
 
 
 .scale-up-horizontal-left {
    animation: scale-up-horizontal-left 0.4s
 }
 
 @keyframes scale-up-horizontal-left {
    0% {
       transform: scaleX(.4);
       transform-origin: left center;
       -webkit-transform: scaleX(.4);
       -moz-transform: scaleX(.4);
       -ms-transform: scaleX(.4);
       -o-transform: scaleX(.4);
    }
 
    100% {
       transform: scaleX(1);
       transform-origin: left center
    }
 }
 
 
 
 
 
 
 
 
 .prodcut-card-tv {
    border: 0px solid #000;
    width: 100%;
    height: 90%;
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgb(0, 0, 0) 100%);
    position: absolute;
    bottom: 0;
 
 }
 
 .radio-tile-group {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
 }
 
 
 .radio-tile-group .input-container {
    position: relative;
    width: 100%;
    height: 100%;
    margin: 0.5rem;
 }
 
 .radio-tile-group .input-container .radio-button {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    margin: 0;
    cursor: pointer;
 }
 
 .radio-tile-group .input-container .radio-tile {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    /* background: #fff; */
    border: 5px solid #e8e8e8;
    border-radius: 30px;
    padding: 8rem 1rem;
    transition: transform 300ms ease;
    -webkit-border-radius: 30px;
    -moz-border-radius: 30px;
    -ms-border-radius: 30px;
    -o-border-radius: 30px;
 }
 
 
 .radio-tile-group .input-container .icon-pay {
    fill: #079ad9;
    width: 3rem;
    height: 3rem;
 }
 
 .radio-tile-group .input-container .radio-tile-label {
    text-align: center;
    font-size: 2.2rem;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 1px;
 
 }
 
 .radio-tile-group .input-container .radio-button:checked+.radio-tile {
    background-color: #079ad9;
    border: 2px solid #079ad9;
    color: white;
    transform: scale(1.1, 1.1);
    font-weight: 800;
 }
 
 .radio-tile-group .input-container .radio-button:checked+.radio-tile .icon-pay {
    fill: white;
    background-color: #079ad9;
    font-weight: 800;
 }
 
 .icon-pay {
    width: 170px;
 }
 
 .img-pay-met-kk {
    width: 250px;
    height: 370px;
    object-fit: contain;
 }
 
 .radio-tile-group .input-container .radio-button:checked+.radio-tile .radio-tile-label {
    color: white;
    /* background-color: #07c0d9; */
    font-weight: 800;
 }
 
 .radio-tile-group .input-container .radio-button:focus {
    border: 10px solid #fa1b1b;
    border-radius: 30px;
 }
 
 
 .btn-warning-kk {
    border: #0025B8 dashed 3px;
    color: #0025B8;
 }
 
 .btn-warning-kk:hover {
    border: #0025B8 dashed 3px;
 
    color: #0025B8;
    background-color: #0025b820;
 }
 
 .btn-warning-kk:active {
    transform: scale(.85);
    -webkit-transform: scale(.85);
    -moz-transform: scale(.85);
    -ms-transform: scale(.85);
    -o-transform: scale(.85);
 }
 
 .kk-text-2 {
    font-size: 23px;
    font-weight: 700;
 }
 
 
 /* .scale-hover:active{
    transform: scale(.85);
    -webkit-transform: scale(.85);
    -moz-transform: scale(.85);
    -ms-transform: scale(.85);
    -o-transform: scale(.85);
 }*/
 
 
 
 .scale-hover-sm:focus {
    transform: scale(1.10) !important;
    -webkit-transform: scale(1.10) !important;
    -moz-transform: scale(1.10) !important;
    -ms-transform: scale(1.10) !important;
    -o-transform: scale(1.10) !important;
 }
 
 
 
 
 .icon-focus {
    width: 100px;
    height: auto;
    display: none;
 }
 
 .icon-focus:focus::before {
    opacity: 1;
 }
 
 .icon-focus:not(:focus)::before {
    display: none;
 }
 
 .postition-icon-sm {
    top: 65%;
    z-index: 999;
    left: 30%;
 }
 
 .postition-icon-md {
    bottom: -10%;
    z-index: 999;
    left: 37%;
 }
 
 .postition-icon-promotion {
    top: 55%;
    left: 10%;
 }
 
 .postition-icon-categories {
    bottom: -15%;
    right: 40%;
    z-index: 999;
 }
 
 .position-icon-segments {
    bottom: -21%;
    right: 40%;
    z-index: 999;
 
 }
 
 .postition-icon-count {
    bottom: -21%;
    right: 40%;
    z-index: 999;
 }
 
 .postition-icon-count {
    bottom: -70%;
    z-index: 999;
    left: -15%;
 }
 
 .postition-icon-product {
    bottom: 4%;
    left: 0%;
    z-index: 999;
 }
 
 .postition-icon-btn-accions {
    bottom: -10%;
    left: 80%;
    z-index: 999;
 }
 
 .postition-icon-key {
    bottom: -40%;
    left: 0%;
    z-index: 999;
 }
 
 
 .postition-icon-list {
    bottom: -40%;
    right: 0%;
    z-index: 999;
 }
 
 .postition-icon-dis {
    bottom: -75%;
    right: 0%;
    z-index: 999;
 }
 
 
 .postition-icon-pays {
    bottom: 10%;
    z-index: 999;
    left: 37%;
 }
 
 
 .focus-pay:focus {
    border: 10px solid rgb(240, 188, 18);
    border-radius: 30px;
 }
 
 .postition-icon-details {
    top: 0;
    left: 22%;
    z-index: 999;
 }
 
 .postition-icon-pays {
    bottom: -10%;
    z-index: 999;
    left: 5%;
 }
 
 .postition-icon-edit {
    top: 50%;
    z-index: 999;
    right: 50%;
 }
 
 details:focus {
    border: none;
 }
 
 
 /* !Revisar  */
 
 .list-group-item {
    background-color: var(--bs-light);
    border: 1px solid #cfcfcf73;
    border-radius: 1px;
    -webkit-border-radius: 1px;
    -moz-border-radius: 1px;
    -ms-border-radius: 1px;
    -o-border-radius: 1px;
 }
 
 .list-group-radio .list-group-item:hover,
 .list-group-radio .list-group-item:focus {
    color: var(--blanco) !important;
    background-color: var(--primary-vending) !important;
 }
 
 .list-group-item-check:hover+.list-group-item {
    background-color: var(--bs-light);
    border: 1px solid #007bff;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
 }
 
 .list-group-item-check:checked+.list-group-item {
    color: #007bff;
    ;
    background-color: var(--bs-light);
    border: 1px solid #007bff;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
 }
 
 
 .list-group-item-check[disabled]+.list-group-item,
 .list-group-item-check:disabled+.list-group-item {
    pointer-events: none;
    filter: none;
    opacity: .5;
 }
 
 
 
 
 .msg-pay {
    margin: 25rem 0;
 }
 
 .msg-pay-bottom {
    margin: 10rem 0;
 }
 
 .msg-position {
    top: 10%;
 
 }
 
 .display-5-kk {
 
    font-size: calc(1.425rem + 2.1vw);
    font-weight: 300;
    line-height: 1.2;
 
 }
 
 /*Section: Responisve */
 
 /* Title: Responisve Mobile */
 
 /*~ +++++ Media Queries  ++++++ */
 /* SECTION:DESKTOP or SELFT CHECKOUT */
 @media screen and (max-width:1081px) {
 
    .kiosk-title-discount {
       font-size: 28px;
    }
 
    /* Utilits */
    ::-webkit-scrollbar {
       display: none;
    }
 
    .carousel-item img {
       width: 100%;
       height: 80vh;
       object-fit: cover;
       /*background-size: contain;
       */
       /* border: 5px solid white; */
    }
 
    .kiosk {
       display: flex;
       height: 100vh;
       display: flex;
       justify-content: center;
 
       /* border: 1px solid #a93c3c;
       background-color: var(--new-frame); */
    }
 
    /* Utilits */
    ::-webkit-scrollbar {
       display: none;
    }
 
 
    .kiosk-container {
       /* background-color: #ffffff00; */
       width: 100%;
       height: 100%;
       display: flex;
       flex-direction: column;
       flex-wrap: nowrap;
       /* align-content: flex-start; */
    }
 
    .kiosk-container_section_lista {
       padding: 0px 10px 135px 0px;
       /* border: 2px solid red; */
       overflow-x: auto;
    }
 
    .kiosk-container_section_title {
       font-size: 60px;
    }
 
 
    /*~ Cards */
    .kiosk-card-item {
       width: 18rem;
       height: 530px;
       border-radius: 15px !important;
       -webkit-border-radius: 15px !important;
       -moz-border-radius: 15px !important;
       -ms-border-radius: 15px !important;
       -o-border-radius: 15px !important;
    }
 
    .display-5-kk {
       font-size: 36px;
       margin: 0px 10px;
    }
 
 
    .kiosk-text-card {
       font-size: 36px;
       font-weight: 700;
    }
 
    .card-img-top {
       width: 100%;
       height: 150px;
       object-fit: cover;
       border-radius: 30px 30px 0px 0px;
       -webkit-border-radius: 30px 30px 0px 0px;
       -moz-border-radius: 30px 30px 0px 0px;
       -ms-border-radius: 30px 30px 0px 0px;
       -o-border-radius: 30px 30px 0px 0px;
    }
 
    .card-img-top>img {
       width: 100%;
       height: 315px;
       object-fit: cover;
       border-radius: 15px 15px 0px 0px !important;
       -webkit-border-radius: 15px 15px 0px 0px !important;
       -moz-border-radius: 15px 15px 0px 0px !important;
       -ms-border-radius: 15px 15px 0px 0px !important;
       -o-border-radius: 15px 15px 0px 0px !important;
    }
 
    /* Header of the Sections  */
    .kiosk-delivery_header_img {
       width: 220px;
       height: 110px;
       object-fit: cover;
       /* filter: drop-shadow(0 2px 5px rgba(158, 158, 158, 0.7)); */
    }
 
 
 
    /*~ Delivery Section  */
 
    .delivery {
       margin-top: 5px;
    }
 
    .kiosk-delivery_title {
       font-size: 50px;
       font-weight: 800;
       margin: 60px 0px 0px 0px;
    }
 
    .kiosk-delivery_btn {
       margin-top: 100px;
       display: flex;
       justify-content: center;
    }
 
    .kiosk-delivery_btns>button {
       margin: 20px;
       border-radius: 45px;
       -webkit-border-radius: 45px;
       -moz-border-radius: 45px;
       -ms-border-radius: 45px;
       -o-border-radius: 45px;
    }
 
    .kiosk-text-delivery {
       font-size: 60px;
       font-weight: 700;
    }
 
    .btn-img-delivery {
       /* width:215px;  */
       font-size: 280px;
    }
 
    .btn-card-delivery {
       color: #0D6EFD;
       padding: 14rem 60px;
    }
 
 
    /* ~ Categories */
 
 
    /** Btn Shopping Cart  */
    .btn-cart {
       fill: #fff;
       cursor: pointer;
       /* background-color: var(--primary-vending); */
       margin-top: 10px;
       padding: 24px;
       width: 130px;
       height: 130px;
       border-radius: 50%;
       -webkit-border-radius: 50%;
       -moz-border-radius: 50%;
       -ms-border-radius: 50%;
       -o-border-radius: 50%;
    }
 
    .kiosk-count {
       top: -12px;
       left: -22px;
       font-size: 20px;
    }
 
    .nav-button {
       font-size: 4rem;
       transform: translateY(-50%);
       background-color: rgba(255, 255, 255, .15);
       -webkit-backdrop-filter: blur(5px);
       backdrop-filter: blur(5px);
       color: #fff;
       padding: 5px 23px;
       cursor: pointer;
    }
 
    /* ~ Buttons Exits */
    .button-ft_0 {
       fill: var(--blanco);
       cursor: pointer;
       background-color: var(--primario);
       width: 150px;
       height: 150px;
       padding: 20px 15px 20px 5px;
       border-radius: 0% 100% 0% 100% / 100% 100% 0% 0%;
       -webkit-border-radius: 0% 100% 0% 100% / 100% 100% 0% 0%;
       -moz-border-radius: 0% 100% 0% 100% / 100% 100% 0% 0%;
       -ms-border-radius: 0% 100% 0% 100% / 100% 100% 0% 0%;
       -o-border-radius: 0% 100% 0% 100% / 100% 100% 0% 0%;
    }
 
 
    .button-ft_1 {
       fill: var(--blanco);
       cursor: pointer;
       background-color: var(--primary-vending);
       width: 152px;
       height: 152px;
       border-radius: 50%;
       -webkit-border-radius: 50%;
       -moz-border-radius: 50%;
       -ms-border-radius: 50%;
       -o-border-radius: 50%;
    }
 
 
    .button-ft,
    .button-ft_0:active,
    .button-ft_1:active {
       transform: scale(.92);
    }
 
    .kiosk-text-categories {
       font-size: 24px;
       font-weight: 800;
    }
 
    /*~ Productos */
 
    .image-empty {
       width: 100px;
       height: 100px;
       opacity: .50;
    }
 
    .kiosk-text-product {
       width: 315px;
       font-size: 35px;
       font-weight: 800;
    }
 
    .kiosk-card-body {
       flex: 1 1 auto;
       padding: 0px 0px;
       color: var(--bs-card-color);
    }
 
    .kiosk-text-card-product {
       font-size: 30px;
    }
 
    .card-img-top-products {
       width: 100%;
       height: 300px;
       object-fit: cover;
       border-radius: 10px 10px 0px 0px;
       -webkit-border-radius: 10px 10px 0px 0px;
       -moz-border-radius: 10px 10px 0px 0px;
       -ms-border-radius: 10px 10px 0px 0px;
       -o-border-radius: 10px 10px 0px 0px;
    }
 
    /*~ lIST CATEGORIES in porducts  */
    .container-list-kiosk {
       height: 345px;
       padding: 2px 0px;
       /* background-color: #000428; */
    }
 
    .container-list-kiosk label {
       width: auto;
       font-size: 40px;
       font-weight: 700;
    }
 
    .button-container-kk img {
       width: 150px;
       height: 150px;
       margin: 5px auto;
       display: block;
    }
 
    .container-list-kiosk ul {
       list-style: none;
       margin: 5px;
       padding: 0;
    }
 
    .container-list-kiosk li {
       display: inline-block;
       margin: 0px 0px;
    }
 
    .rounded-product-kiosk {
       border-radius: 1rem;
       -webkit-border-radius: 1rem;
       -moz-border-radius: 1rem;
       -ms-border-radius: 1rem;
       -o-border-radius: 1rem;
    }
 
    /* Aplica un margen de 10px a todos los elementos de la lista */
    .kiosk-card-item_product {
       margin: 0px;
    }
 
    /* Aplica un margen izquierdo de 20px a los elementos mayores que el tercer hijo */
    .kiosk-card-item_product:nth-child(n + 4) {
       margin-top: 20px;
    }
 
 
    /*~ Info Products */
 
    .kiosk-segments-backgound {
       position: relative;
       width: 100%;
       height: 500px;
       box-shadow: rgba(9, 30, 66, 0.25) 0px 1px 1px, rgba(9, 30, 66, 0.13) 0px 0px 1px 1px;
       /* border-radius: 40px;
       -webkit-border-radius: 40px;
       -moz-border-radius: 40px;
       -ms-border-radius: 40px;
       -o-border-radius: 40px; */
       background-repeat: no-repeat;
       background-position: center;
       background-size: cover;
    }
 
    .icon-info-product {
       font-size: 45px;
       color: #fff;
       background-color: #29292946;
       -webkit-backdrop-filter: blur(5px);
       backdrop-filter: blur(5px);
       top: 0;
       right: 0;
       fill: #fff;
       cursor: pointer;
       /* background-color: var(--primary-vending); */
       margin-top: 11px;
       padding: 12px;
       width: 90px;
       height: 90px;
       border-radius: 50%;
       -webkit-border-radius: 50%;
       -moz-border-radius: 50%;
       -ms-border-radius: 50%;
       -o-border-radius: 50%;
    }
 
    .btn-close-img-info {
       font-size: 60px;
    }
 
    .img-modal-info {
       width: 100%;
       height: 50vh;
 
       object-fit: cover;
       border-radius: 60px;
       -webkit-border-radius: 60px;
       -moz-border-radius: 60px;
       -ms-border-radius: 60px;
       -o-border-radius: 60px;
    }
 
    .modal-xl-img-info {
       width: 975px !important;
    }
 
    .product-card-tv {
       width: 100%;
       height: 450px;
       /* border-radius: 40px;
       -webkit-border-radius: 40px;
       -moz-border-radius: 40px;
       -ms-border-radius: 40px;
       -o-border-radius: 40px; */
       background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgb(0, 0, 0) 100%);
       position: absolute;
       bottom: 0;
 
    }
 
 
    .kiosk-description-product {
       font-size: 2rem;
       font-weight: 600;
    }
 
    /* ~List Crad Option Segments */
    label.radio-card {
       cursor: pointer;
    }
 
    label.radio-card .card-content-wrapper {
       scale: 1;
       background: #ffffff;
       border: solid 2px #e3e3e3;
       border-radius: 9px;
       width: 200px;
       height: 370px;
       padding: 5px;
       position: relative;
       overflow: hidden;
 
       display: grid;
       box-shadow: 0 2px 4px 0 rgba(219, 215, 215, 0.04);
       transition: 200ms linear;
       -webkit-border-radius: 9px;
       -moz-border-radius: 9px;
       -ms-border-radius: 9px;
       -o-border-radius: 9px;
    }
 
    label.radio-card .check-icon {
       width: 10px;
       height: 10px;
       display: flex;
       border: solid 2px #e3e3e3;
       border-radius: 50%;
       transition: 200ms linear;
       position: relative;
       justify-content: flex-end;
       -webkit-border-radius: 50%;
       -moz-border-radius: 50%;
       -ms-border-radius: 50%;
       -o-border-radius: 50%;
    }
 
    label.radio-card .check-icon:before {
       content: "";
 
       /* background-image: url("data:image/svg+xml,%3Csvg width='12' height='9' viewBox='0 0 12 9' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0.93552 4.58423C0.890286 4.53718 0.854262 4.48209 0.829309 4.42179C0.779553 4.28741 0.779553 4.13965 0.829309 4.00527C0.853759 3.94471 0.889842 3.88952 0.93552 3.84283L1.68941 3.12018C1.73378 3.06821 1.7893 3.02692 1.85185 2.99939C1.91206 2.97215 1.97736 2.95796 2.04345 2.95774C2.11507 2.95635 2.18613 2.97056 2.2517 2.99939C2.31652 3.02822 2.3752 3.06922 2.42456 3.12018L4.69872 5.39851L9.58026 0.516971C9.62828 0.466328 9.68554 0.42533 9.74895 0.396182C9.81468 0.367844 9.88563 0.353653 9.95721 0.354531C10.0244 0.354903 10.0907 0.369582 10.1517 0.397592C10.2128 0.425602 10.2672 0.466298 10.3112 0.516971L11.0651 1.25003C11.1108 1.29672 11.1469 1.35191 11.1713 1.41247C11.2211 1.54686 11.2211 1.69461 11.1713 1.82899C11.1464 1.88929 11.1104 1.94439 11.0651 1.99143L5.06525 7.96007C5.02054 8.0122 4.96514 8.0541 4.90281 8.08294C4.76944 8.13802 4.61967 8.13802 4.4863 8.08294C4.42397 8.0541 4.36857 8.0122 4.32386 7.96007L0.93552 4.58423Z' fill='white'/%3E%3C/svg%3E%0A");
    background-repeat: no-repeat;
    background-size: 7px;
    background-position: center center;
    transform: scale(1.6);
    transition: 200ms linear; */
       opacity: 0;
    }
 
    label.radio-card input[type=radio] {
       visibility: hidden;
       appearance: none;
       -webkit-appearance: none;
       -moz-appearance: none;
    }
 
    label.radio-card input[type=radio]:checked+.card-content-wrapper {
       border: 3px solid #0045f4;
       color: #000000;
       background-color: #4e80ff15;
       /*background: #055aaf;
     */
       /*background: -webkit-linear-gradient(to bottom, #042dbf, #0575E6);
     background: linear-gradient(to bottom, #042dbf, #0575E6);
     */
 
       border-radius: 10px;
       -webkit-border-radius: 10px;
       -moz-border-radius: 10x;
       -ms-border-radius: 10x;
       -o-border-radius: 10x;
    }
 
    label.radio-card input[type=radio]:checked+.card-content-wrapper .check-icon {
       background: #3057d5;
       border-color: #3056d5c5;
       transform: scale(1.2);
    }
 
    label.radio-card input[type=radio]:checked+.card-content-wrapper .check-icon:before {
       transform: scale(1);
       opacity: 1;
    }
 
    label.radio-card input[type=radio]:focus+.card-content-wrapper .check-icon {
       box-shadow: 0 0 0 4px rgba(48, 86, 213, 0.2);
       border-color: #3056d5;
    }
 
 
    label.radio-card .card-content img {
       width: 185px;
       height: 185px;
       margin-bottom: 10px;
       border-radius: 10px;
       -webkit-border-radius: 10px;
       -moz-border-radius: 10px;
       -ms-border-radius: 10px;
       -o-border-radius: 10px;
       scale: 1;
    }
 
    label.radio-card .card-content h4 {
       width: 180px;
       font-size: 28px;
       letter-spacing: -0.24px;
       text-align: start;
       white-space: nowrap;
       overflow: hidden;
       text-overflow: ellipsis;
       font-weight: 500;
       color: #000;
       margin-bottom: 10px;
       font-weight: 800;
    }
 
    @keyframes marquee {
       0% {
          transform: translateX(0);
       }
 
       100% {
          transform: translateX(-100%);
       }
    }
 
 
    label.radio-card input[type=radio]:checked+.card-content-wrapper h4 {
       animation: marquee 5s infinite linear;
       white-space: nowrap;
       width: 100%;
       margin: 0;
       -webkit-animation: marquee 5s infinite linear;
    }
 
    label.radio-card .card-content h5 {
       font-size: 30px;
       line-height: 1.4;
       color: #217fd6;
       font-weight: 800;
       position: absolute;
       /* Establece el div como absoluto */
       bottom: 0;
       /* Lo coloca en la parte inferior del contenedor */
       left: 50%;
       /* Lo coloca en la mitad horizontal del contenedor */
       transform: translateX(-50%);
       /* Corrige el centrado horizontal */
       background-color: #ffffff00;
       padding: 0px;
    }
 
 
 
    .kiosk-lista-container-product {
       padding: 1px 0px 0px 0px;
       /* border: 2px solid red; */
       overflow-x: auto;
    }
 
    .container-card-content-wrapper {
       margin-bottom: 2px;
 
    }
 
    .container-card-content-wrapper:last-child {
       margin-bottom: 180px;
    }
 
    /* ~ Order Summary */
    .title-order-section {
       font-size: 3.5rem;
       font-weight: 800;
    }
 
    .button-discont {
       font-size: 2.5rem
    }
 
    .order-item {
       margin: 20px 10px;
    }
 
    .order-item:first-child {
       margin: 10px 10px;
    }
 
    .img-order-summary {
       width: 125px;
       height: 125px;
    }
 
    .title-product-text {
       font-size: 40px;
    }
 
    .summary-text {
       font-size: 30px;
 
    }
 
    .detail-text {
       font-size: 25px;
    }
 
    .btn-dash {
       background-color: red;
    }
 
    .btn-plus {
       background-color: green;
    }
 
    .btn-dash:active {
       transform: scale(.85);
       -webkit-transform: scale(.85);
       -moz-transform: scale(.85);
       -ms-transform: scale(.85);
       -o-transform: scale(.85);
    }
 
    .btn-plus:active {
       transform: scale(.85);
       -webkit-transform: scale(.85);
       -moz-transform: scale(.85);
       -ms-transform: scale(.85);
       -o-transform: scale(.85);
    }
 
    .count-items {
       font-size: 40px;
    }
 
    .dash,
    .plus {
       color: white;
       font-size: 15px;
       font-weight: 800;
       padding: 5px;
    }
 
    .btn-add-order {
       font-size: 36px;
       font-weight: 700;
       padding: 8px;
    }
 
    .btn-cancel-order {
       font-size: 48px;
       font-weight: 700;
       padding: 12px;
    }
 
    .btn-send-order {
       font-size: 48px;
       font-weight: 700;
       padding: 12px;
    }
 
    .dash-icon,
    .plus-icon {
       color: white;
       font-size: 38px;
       font-weight: 800;
       padding: 1px 15px;
       border-radius: 20px;
       -webkit-border-radius: 20px;
       -moz-border-radius: 20px;
       -ms-border-radius: 20px;
       -o-border-radius: 20px;
    }
 
    .text-count-orders {
       font-size: 25px;
    }
 
    .text-msg-empty-orders {
       font-size: 20px;
    }
 
 
    .text-total-order {
       font-size: 46px;
    }
 
    .text-subtitel-order {
       font-size: 29.5px;
    }
 
    /* Modal edit user  */
    .kiosk-key-letter {
       font-size: 70px;
       font-weight: 800;
       padding: 23px 25px;
       border: 2px solid var(--new-main-8);
       border-radius: 30px;
       -webkit-border-radius: 30px;
       -moz-border-radius: 30px;
       -ms-border-radius: 30px;
       -o-border-radius: 30px;
       margin: 2px;
    }
 
    .keypadPrimary-input_1 {
       color: var(--primary-vending);
       width: 100%;
       margin: 0;
       padding: 15px 0px;
       font-size: 25px;
       font-weight: 700;
       text-align: center;
       border: 2px solid rgb(226, 226, 226);
       letter-spacing: 25px;
    }
 
    .keyboard-pad>.num,
    .delete {
       color: #000;
       font-weight: 700;
       font-size: 65px;
       border-radius: 10px;
       -webkit-border-radius: 10px;
       -moz-border-radius: 10px;
       -ms-border-radius: 10px;
       -o-border-radius: 10px;
       padding: 50px 0;
       width: calc(33.333% - 8px);
       margin: 0 4px 8px;
       text-align: center;
       background-color: rgba(241, 241, 241, 0.8);
       cursor: pointer;
       transition: all 0.2s ease-in-out;
       -webkit-transition: all 0.2s ease-in-out;
       -moz-transition: all 0.2s ease-in-out;
       -ms-transition: all 0.2s ease-in-out;
       -o-transition: all 0.2s ease-in-out;
       border: 1px solid rgb(226, 226, 226);
       box-shadow: 2px 2px 0 rgb(0, 0, 0, .2);
       inset: 4px -4px 0px #d4c69d;
       inset: -4px 4px 0px #beb28d;
    }
 
    .section-keyboard {
       top: 0;
       left: 0;
       width: auto;
       height: 100%;
       display: flex;
       flex-direction: column;
       border-radius: 30px;
       -webkit-border-radius: 30px;
       -moz-border-radius: 30px;
       -ms-border-radius: 30px;
       -o-border-radius: 30px;
       padding: 7px;
    }
 
    .keyboard-pad>.keyboardKey {
       color: #000;
       font-weight: 800;
       text-align: center;
       font-size: 2rem;
       border-radius: 10px;
       -webkit-border-radius: 10px;
       -moz-border-radius: 10px;
       -ms-border-radius: 10px;
       -o-border-radius: 10px;
       padding: 45px 0px;
       width: calc(12.333% - 8px);
       margin: 0 4px 8px;
       cursor: pointer;
       transition: all 0.2s ease-in-out;
       -webkit-transition: all 0.2s ease-in-out;
       -moz-transition: all 0.2s ease-in-out;
       -ms-transition: all 0.2s ease-in-out;
       -o-transition: all 0.2s ease-in-out;
       border: 2px solid rgb(226, 226, 226);
       /*box-shadow: 2px 2px 0 rgb(0, 0, 0, .2);
    */
       inset: 4px -4px 0px #d4c69d;
       inset: -4px 4px 0px #beb28d;
    }
 
    .keyboard-pad>.delete {
       color: #000;
       font-weight: 800;
       text-align: center;
       font-size: 2rem;
       border-radius: 10px;
       -webkit-border-radius: 10px;
       -moz-border-radius: 10px;
       -ms-border-radius: 10px;
       -o-border-radius: 10px;
       padding: 45px 0px;
       width: calc(20.333% - 8px);
       margin: 0 4px 8px;
       cursor: pointer;
       transition: all 0.2s linear;
       -webkit-transition: all 0.2s linear;
       -moz-transition: all 0.2s linear;
       -ms-transition: all 0.2s linear;
       -o-transition: all 0.2s linear;
       border: 2px solid rgb(226, 226, 226);
       /* box-shadow: 2px 2px 0 rgb(0, 0, 0, .2); */
       inset: 4px -4px 0px #d4c69d;
       inset: -4px 4px 0px #beb28d;
    }
 
    .keyboard-pad>.num:hover {
       color: var(--primary-vending);
       background-color: #bae4dd37;
    }
 
    .num:hover {
       box-shadow: 3px 3px 2px rgb(0, 0, 0, .25);
       inset: 3px -3px 0px #d4c69d;
       inset: -3px 3px 0px #beb28d;
       transform: translate(3px, 3px);
       -webkit-transform: translate(3px, 3px);
       -moz-transform: translate(3px, 3px);
       -ms-transform: translate(3px, 3px);
       -o-transform: translate(3px, 3px);
       transition: 0.2s ease;
       -webkit-transition: 0.2s ease;
       -moz-transition: 0.2s ease;
       -ms-transition: 0.2s ease;
       -o-transition: 0.2s ease;
    }
 
    .delete:hover {
       box-shadow: 3px 3px 2px rgb(0, 0, 0, .25);
       inset: 3px -3px 0px #d49d9d;
       inset: -3px 3px 0px #be938d;
       transform: scale(-1px, -1px);
       -webkit-transform: scale(-1px, -1px);
       -moz-transform: scale(-1px, -1px);
       -ms-transform: scale(-1px, -1px);
       -o-transform: scale(-1px, -1px);
       transition: 0.2s ease;
       -webkit-transition: 0.2s ease;
       -moz-transition: 0.2s ease;
       -ms-transition: 0.2s ease;
       -o-transition: 0.2s ease;
    }
 
    .keypadPrimary-input_1 {
       color: var(--primary-vending);
       width: 100%;
       margin: 30px 0;
       padding: 15px 0;
       font-size: 60px;
       font-weight: 700;
       text-align: center;
       border: 2px solid rgb(226, 226, 226);
       letter-spacing: 25px;
    }
 
 
    /* ~Status Payments  */
 
    .msg-pay {
       margin: 1px 0;
    }
    .loading-status-pay {
       width: 250px;
       height: 250px;
   }
 
    .img-check-pay {
       width: 61px;
       height: 58px;
   }
 
    /* ~Modal discount */
    /* ~Modal Discounts */
 
    .kiosk-img-discount {
       width: 190px;
       height: 190px;
    }
 
    .kiosk-text-discount {
       font-size: 40px;
       font-weight: 800;
       margin: 10px 0px 0px 0px;
    }
 
    /* Paymnets */
 
    .radio-tile-group .input-container .radio-tile {
       display: flex;
       flex-direction: column;
       align-items: center;
       justify-content: center;
       width: 100%;
       height: 100%;
       /* background: #fff; */
       border: 5px solid #e8e8e8;
       border-radius: 30px;
       padding: 5rem 0rem;
       transition: transform 300ms ease;
       -webkit-border-radius: 30px;
       -moz-border-radius: 30px;
       -ms-border-radius: 30px;
       -o-border-radius: 30px;
    }
 
    .radio-tile-group .input-container .radio-tile-label {
       text-align: center;
       font-size: 36px;
       font-weight: 600;
       text-transform: uppercase;
       letter-spacing: 1px;
    }
 }
 
 
 /* SECTION:Tablet  */
 @media screen and (max-width:768px) {
 
    .kiosk-title-discount {
       font-size: 38px;
    }
 
    /* Utilits */
    ::-webkit-scrollbar {
       display: none;
    }
 
    .kiosk {
       display: flex;
       height: 100vh;
       display: flex;
       justify-content: center;
 
       /* border: 1px solid #a93c3c;
       background-color: var(--new-frame); */
    }
 
    /* Utilits */
    ::-webkit-scrollbar {
       display: none;
    }
 
 
    .kiosk-container {
       background-color: #bcce17;
       width: 100%;
       height: 100%;
       display: flex;
       flex-direction: column;
       flex-wrap: nowrap;
       /* align-content: flex-start; */
    }
 
    .kiosk-container_section_lista {
       padding: 50px 10px 80px 0px;
       /* border: 2px solid red; */
       overflow-x: auto;
    }
 
    .kiosk-container_section_title {
       font-size: 35px;
    }
 
 
    /*~ Cards */
    .kiosk-card-item {
       width: 15rem;
       height: 530px;
       border-radius: 15px !important;
       -webkit-border-radius: 15px !important;
       -moz-border-radius: 15px !important;
       -ms-border-radius: 15px !important;
       -o-border-radius: 15px !important;
    }
 
    .display-5-kk {
       font-size: 20px;
    }
 
 
    .kiosk-text-card {
       font-size: 36px;
       font-weight: 700;
    }
 
    .card-img-top {
       width: 100%;
       height: 150px;
       object-fit: cover;
       border-radius: 30px 30px 0px 0px;
       -webkit-border-radius: 30px 30px 0px 0px;
       -moz-border-radius: 30px 30px 0px 0px;
       -ms-border-radius: 30px 30px 0px 0px;
       -o-border-radius: 30px 30px 0px 0px;
    }
 
    .card-img-top>img {
       width: 100%;
       height: 250px;
       object-fit: cover;
       border-radius: 15px 15px 0px 0px !important;
       -webkit-border-radius: 15px 15px 0px 0px !important;
       -moz-border-radius: 15px 15px 0px 0px !important;
       -ms-border-radius: 15px 15px 0px 0px !important;
       -o-border-radius: 15px 15px 0px 0px !important;
    }
 
    /* Header of the Sections  */
    .kiosk-delivery_header_img {
       width: 100px;
       height: 50px;
       object-fit: cover;
       /* filter: drop-shadow(0 2px 5px rgba(158, 158, 158, 0.7)); */
    }
 
 
 
    /*~ Delivery Section  */
 
    .delivery {
       margin-top: 5px;
    }
 
    .kiosk-delivery_title {
       font-size: 50px;
       font-weight: 800;
       margin: 60px 0px 0px 0px;
    }
 
    .kiosk-delivery_btn {
       margin-top: 100px;
       display: flex;
       justify-content: center;
    }
 
    .kiosk-delivery_btns>button {
       margin: 20px;
       border-radius: 45px;
       -webkit-border-radius: 45px;
       -moz-border-radius: 45px;
       -ms-border-radius: 45px;
       -o-border-radius: 45px;
    }
 
    .kiosk-text-delivery {
       font-size: 36px;
       font-weight: 700;
    }
 
    .btn-img-delivery {
       /* width:215px;  */
       font-size: 180px;
    }
 
    .btn-card-delivery {
       color: #0D6EFD;
       padding: 5.3rem 44px;
    }
 
 
    /* ~ Categories */
 
 
    /** Btn Shopping Cart  */
    .btn-cart {
       fill: #fff;
       cursor: pointer;
       /* background-color: var(--primary-vending); */
       margin-top: 10px;
       padding: 12px;
       width: 70px;
       height: 70px;
       border-radius: 50%;
       -webkit-border-radius: 50%;
       -moz-border-radius: 50%;
       -ms-border-radius: 50%;
       -o-border-radius: 50%;
    }
 
    .kiosk-count {
       top: -12px;
       left: -22px;
       font-size: 20px;
    }
 
    .nav-button {
       font-size: 4rem;
       transform: translateY(-50%);
       background-color: rgba(255, 255, 255, .15);
       -webkit-backdrop-filter: blur(5px);
       backdrop-filter: blur(5px);
       color: #fff;
       padding: 5px 23px;
       cursor: pointer;
    }
 
    /* ~ Buttons Exits */
    .button-ft_0 {
       fill: var(--blanco);
       cursor: pointer;
       background-color: var(--primario);
       width: 150px;
       height: 150px;
       padding: 20px 15px 20px 5px;
       border-radius: 0% 100% 0% 100% / 100% 100% 0% 0%;
       -webkit-border-radius: 0% 100% 0% 100% / 100% 100% 0% 0%;
       -moz-border-radius: 0% 100% 0% 100% / 100% 100% 0% 0%;
       -ms-border-radius: 0% 100% 0% 100% / 100% 100% 0% 0%;
       -o-border-radius: 0% 100% 0% 100% / 100% 100% 0% 0%;
    }
 
 
    .button-ft_1 {
       fill: var(--blanco);
       cursor: pointer;
       background-color: var(--primary-vending);
       width: 75px;
       height: 75px;
       border-radius: 50%;
       -webkit-border-radius: 50%;
       -moz-border-radius: 50%;
       -ms-border-radius: 50%;
       -o-border-radius: 50%;
    }
 
 
    .button-ft,
    .button-ft_0:active,
    .button-ft_1:active {
       transform: scale(.92);
    }
 
    .kiosk-text-categories {
       font-size: 17px;
       font-weight: 800;
    }
 
    /*~ Productos */
 
    .image-empty {
       width: 100px;
       height: 100px;
       opacity: .50;
    }
 
    .kiosk-text-product {
       width: 104px;
       font-size: 15px;
       font-weight: 800;
    }
 
    .kiosk-card-body {
       flex: 1 1 auto;
       padding: 0px 0px;
       color: var(--bs-card-color);
    }
 
    .kiosk-text-card-product {
       font-size: 15px;
    }
 
    .card-img-top-products {
       width: 100%;
       height: 100px;
       object-fit: cover;
       border-radius: 10px 10px 0px 0px;
       -webkit-border-radius: 10px 10px 0px 0px;
       -moz-border-radius: 10px 10px 0px 0px;
       -ms-border-radius: 10px 10px 0px 0px;
       -o-border-radius: 10px 10px 0px 0px;
    }
 
    /*~ lIST CATEGORIES in porducts  */
    .container-list-kiosk {
       height: 81px;
       padding: 2px 0px;
    }
 
    .container-list-kiosk label {
       width: auto;
       font-size: 15px;
       font-weight: 700;
    }
 
    .button-container-kk img {
       width: 20px;
       height: 20px;
       margin: 5px;
    }
 
    .container-list-kiosk ul {
       list-style: none;
       margin: 5px;
       padding: 0;
    }
 
    .container-list-kiosk li {
       display: inline-block;
       margin: 0px 0px;
    }
 
    /* Aplica un margen de 10px a todos los elementos de la lista */
    .kiosk-card-item_product {
       margin: 0px;
    }
 
    /* Aplica un margen izquierdo de 20px a los elementos mayores que el tercer hijo */
    .kiosk-card-item_product:nth-child(n + 4) {
       margin-top: 20px;
    }
 
 
    /*~ Info Products */
 
    .kiosk-segments-backgound {
       position: relative;
       width: 100%;
       height: 300px;
       box-shadow: rgba(9, 30, 66, 0.25) 0px 1px 1px, rgba(9, 30, 66, 0.13) 0px 0px 1px 1px;
       /* border-radius: 40px;
       -webkit-border-radius: 40px;
       -moz-border-radius: 40px;
       -ms-border-radius: 40px;
       -o-border-radius: 40px; */
       background-repeat: no-repeat;
       background-position: center;
       background-size: cover;
    }
 
    .product-card-tv {
       width: 100%;
       height: 300px;
       /* border-radius: 40px;
       -webkit-border-radius: 40px;
       -moz-border-radius: 40px;
       -ms-border-radius: 40px;
       -o-border-radius: 40px; */
       background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgb(0, 0, 0) 100%);
       position: absolute;
       bottom: 0;
 
    }
 
 
    .kiosk-description-product {
       font-size: 15px;
       font-weight: 600;
    }
 
    /* ~List Crad Option Segments */
    label.radio-card {
       cursor: pointer;
    }
 
    label.radio-card .card-content-wrapper {
       scale: 1;
       background: #ffffff;
       border: solid 2px #e3e3e3;
       border-radius: 9px;
       width: 114px;
       height: 210px;
       padding: 5px;
       position: relative;
       display: grid;
       box-shadow: 0 2px 4px 0 rgba(219, 215, 215, 0.04);
       transition: 200ms linear;
       -webkit-border-radius: 9px;
       -moz-border-radius: 9px;
       -ms-border-radius: 9px;
       -o-border-radius: 9px;
    }
 
    label.radio-card .check-icon {
       width: 10px;
       height: 10px;
       display: flex;
       border: solid 2px #e3e3e3;
       border-radius: 50%;
       transition: 200ms linear;
       position: relative;
       justify-content: flex-end;
       -webkit-border-radius: 50%;
       -moz-border-radius: 50%;
       -ms-border-radius: 50%;
       -o-border-radius: 50%;
    }
 
    label.radio-card .check-icon:before {
       content: "";
 
       /* background-image: url("data:image/svg+xml,%3Csvg width='12' height='9' viewBox='0 0 12 9' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0.93552 4.58423C0.890286 4.53718 0.854262 4.48209 0.829309 4.42179C0.779553 4.28741 0.779553 4.13965 0.829309 4.00527C0.853759 3.94471 0.889842 3.88952 0.93552 3.84283L1.68941 3.12018C1.73378 3.06821 1.7893 3.02692 1.85185 2.99939C1.91206 2.97215 1.97736 2.95796 2.04345 2.95774C2.11507 2.95635 2.18613 2.97056 2.2517 2.99939C2.31652 3.02822 2.3752 3.06922 2.42456 3.12018L4.69872 5.39851L9.58026 0.516971C9.62828 0.466328 9.68554 0.42533 9.74895 0.396182C9.81468 0.367844 9.88563 0.353653 9.95721 0.354531C10.0244 0.354903 10.0907 0.369582 10.1517 0.397592C10.2128 0.425602 10.2672 0.466298 10.3112 0.516971L11.0651 1.25003C11.1108 1.29672 11.1469 1.35191 11.1713 1.41247C11.2211 1.54686 11.2211 1.69461 11.1713 1.82899C11.1464 1.88929 11.1104 1.94439 11.0651 1.99143L5.06525 7.96007C5.02054 8.0122 4.96514 8.0541 4.90281 8.08294C4.76944 8.13802 4.61967 8.13802 4.4863 8.08294C4.42397 8.0541 4.36857 8.0122 4.32386 7.96007L0.93552 4.58423Z' fill='white'/%3E%3C/svg%3E%0A");
    background-repeat: no-repeat;
    background-size: 7px;
    background-position: center center;
    transform: scale(1.6);
    transition: 200ms linear; */
       opacity: 0;
    }
 
    label.radio-card input[type=radio] {
       visibility: hidden;
       appearance: none;
       -webkit-appearance: none;
       -moz-appearance: none;
    }
 
    label.radio-card input[type=radio]:checked+.card-content-wrapper {
       border: 3px solid #0045f4;
       color: #000000;
       background-color: #4e80ff15;
       /*background: #055aaf;
     */
       /*background: -webkit-linear-gradient(to bottom, #042dbf, #0575E6);
     background: linear-gradient(to bottom, #042dbf, #0575E6);
     */
 
       border-radius: 10px;
       -webkit-border-radius: 10px;
       -moz-border-radius: 10x;
       -ms-border-radius: 10x;
       -o-border-radius: 10x;
    }
 
    label.radio-card input[type=radio]:checked+.card-content-wrapper .check-icon {
       background: #3057d5;
       border-color: #3056d5c5;
       transform: scale(1.2);
    }
 
    label.radio-card input[type=radio]:checked+.card-content-wrapper .check-icon:before {
       transform: scale(1);
       opacity: 1;
    }
 
    label.radio-card input[type=radio]:focus+.card-content-wrapper .check-icon {
       box-shadow: 0 0 0 4px rgba(48, 86, 213, 0.2);
       border-color: #3056d5;
    }
 
    label.radio-card .card-content img {
       width: 100px;
       height: 100px;
       margin-bottom: 10px;
       border-radius: 10px;
       -webkit-border-radius: 10px;
       -moz-border-radius: 10px;
       -ms-border-radius: 10px;
       -o-border-radius: 10px;
       scale: 1;
    }
 
    label.radio-card .card-content h4 {
       width: 99px;
       font-size: 14px;
       letter-spacing: -0.24px;
       text-align: start;
       font-weight: 500;
       color: #000;
       margin-bottom: 10px;
       font-weight: 800;
    }
 
    label.radio-card .card-content h5 {
       font-size: 18px;
       line-height: 1.4;
 
       color: #22a009;
       font-weight: 800;
    }
 
    .kiosk-lista-container-product {
       padding: 1px 0px 0px 0px;
       /* border: 2px solid red; */
       overflow-x: auto;
    }
 
    .container-card-content-wrapper {
       margin-bottom: 2px;
 
    }
 
    .container-card-content-wrapper:last-child {
       margin-bottom: 80px;
    }
 
    /* ~ Order Summary */
    .title-order-section {
       font-size: 1.7rem;
       font-weight: 800;
    }
 
    .button-discont {
       font-size: 1.2rem
    }
 
    .order-item {
       margin: 20px 10px;
    }
 
    .order-item:first-child {
       margin: 10px 10px;
    }
 
    .img-order-summary {
       width: 80px;
       height: 80px;
    }
 
    .title-product-text {
       font-size: 18px;
    }
 
    .summary-text {
       font-size: 14px;
 
    }
 
    .detail-text {
       font-size: 13px;
    }
 
    .btn-dash {
       background-color: red;
    }
 
    .btn-plus {
       background-color: green;
    }
 
    .btn-dash:active {
       transform: scale(.85);
       -webkit-transform: scale(.85);
       -moz-transform: scale(.85);
       -ms-transform: scale(.85);
       -o-transform: scale(.85);
    }
 
    .btn-plus:active {
       transform: scale(.85);
       -webkit-transform: scale(.85);
       -moz-transform: scale(.85);
       -ms-transform: scale(.85);
       -o-transform: scale(.85);
    }
 
    .count-items {
       font-size: 25px;
    }
 
    .dash,
    .plus {
       color: white;
       font-size: 15px;
       font-weight: 800;
       padding: 5px;
    }
 
    .btn-add-order {
       font-size: 16px;
       font-weight: 700;
       padding: 8px;
    }
 
    .btn-cancel-order {
       font-size: 18px;
       font-weight: 700;
       padding: 12px;
    }
 
    .btn-send-order {
       font-size: 18px;
       font-weight: 700;
       padding: 12px;
    }
 
    .dash-icon,
    .plus-icon {
       color: white;
       font-size: 15px;
       font-weight: 800;
       padding: 1px 5px;
       border-radius: 20px;
       -webkit-border-radius: 20px;
       -moz-border-radius: 20px;
       -ms-border-radius: 20px;
       -o-border-radius: 20px;
    }
 
    .text-count-orders {
       font-size: 15px;
    }
 
    .text-msg-empty-orders {
       font-size: 20px;
    }
 
 
    .text-total-order {
       font-size: 26px;
    }
 
    .text-subtitel-order {
       font-size: 13.5px;
    }
 
    /* Modal edit user  */
    .kiosk-key-letter {
       font-size: 12px;
       font-weight: 800;
       padding: 7px 11px;
       border: 2px solid var(--new-main-8);
       border-radius: 30px;
       -webkit-border-radius: 30px;
       -moz-border-radius: 30px;
       -ms-border-radius: 30px;
       -o-border-radius: 30px;
       margin: 2px;
    }
 
    .keypadPrimary-input_1 {
       color: var(--primary-vending);
       width: 100%;
       margin: .5rem 0;
       padding: 15px 0;
       font-size: 25px;
       font-weight: 700;
       text-align: center;
       border: 2px solid rgb(226, 226, 226);
       letter-spacing: 25px;
    }
 
 
    /* ~Status Payments  */
 
    .msg-pay {
       margin: 5rem 0;
    }
 
    /* ~Modal discount */
    /* ~Modal Discounts */
 
    .kiosk-img-discount {
       width: 190px;
       height: 190px;
    }
 
    .kiosk-text-discount {
       font-size: 25px;
       font-weight: 800;
       margin: 60px 0px 0px 0px;
    }
 
 }
 
 
 /* SECTION: MOBILE MEDUIM */
 @media screen and (max-width:767px) {
 
 
 
    /* Utilits */
    ::-webkit-scrollbar {
       display: none;
    }
 
    .btn-close-img-info {
       font-size: 30px;
    }
 
    .icon-info-product {
       font-size: 25px;
       color: #fff;
       background-color: #29292946;
       -webkit-backdrop-filter: blur(5px);
       backdrop-filter: blur(5px);
       top: 0;
       right: 0;
       fill: #fff;
       cursor: pointer;
       /* background-color: var(--primary-vending); */
       margin-top: 11px;
       padding: 12px;
       width: 60px;
       height: 60px;
       border-radius: 50%;
       -webkit-border-radius: 50%;
       -moz-border-radius: 50%;
       -ms-border-radius: 50%;
       -o-border-radius: 50%;
    }
 
 
    .kiosk-container {
       background-color: #1723ce;
       width: 100%;
       height: 100%;
       display: flex;
       flex-direction: column;
       flex-wrap: nowrap;
       /* align-content: flex-start; */
    }
 
    .kiosk-container_section_lista {
       padding: 50px 10px 80px 0px;
       /* border: 2px solid red; */
       overflow-x: auto;
    }
 
    .kiosk-container_section_title {
       font-size: 20px;
    }
 
 
    /*~ Cards */
    .kiosk-card-item {
       width: 9rem;
       height: 530px;
       border-radius: 15px !important;
       -webkit-border-radius: 15px !important;
       -moz-border-radius: 15px !important;
       -ms-border-radius: 15px !important;
       -o-border-radius: 15px !important;
    }
 
    .display-5-kk {
       font-size: 20px;
    }
 
 
    .kiosk-text-card {
       font-size: 20px;
       font-weight: 700;
    }
 
    .card-img-top {
       width: 100%;
       height: 150px;
       object-fit: cover;
       border-radius: 30px 30px 0px 0px;
       -webkit-border-radius: 30px 30px 0px 0px;
       -moz-border-radius: 30px 30px 0px 0px;
       -ms-border-radius: 30px 30px 0px 0px;
       -o-border-radius: 30px 30px 0px 0px;
    }
 
    .card-img-top>img {
       width: 100%;
       height: 150px;
       object-fit: cover;
       border-radius: 15px 15px 0px 0px !important;
       -webkit-border-radius: 15px 15px 0px 0px !important;
       -moz-border-radius: 15px 15px 0px 0px !important;
       -ms-border-radius: 15px 15px 0px 0px !important;
       -o-border-radius: 15px 15px 0px 0px !important;
    }
 
    /* Header of the Sections  */
    .kiosk-delivery_header_img {
       width: 100px;
       height: 50px;
       object-fit: cover;
       /* filter: drop-shadow(0 2px 5px rgba(158, 158, 158, 0.7)); */
    }
 
 
 
    /*~ Delivery Section  */
 
    .delivery {
       margin-top: 5px;
    }
 
    .kiosk-delivery_title {
       font-size: 20px;
       font-weight: 800;
       margin: 50px 0px 0px 0px;
    }
 
    .kiosk-delivery_btn {
       margin-top: 50px;
       display: flex;
       justify-content: center;
    }
 
    .kiosk-delivery_btns>button {
       margin: 20px;
       border-radius: 45px;
       -webkit-border-radius: 45px;
       -moz-border-radius: 45px;
       -ms-border-radius: 45px;
       -o-border-radius: 45px;
    }
 
    .kiosk-text-delivery {
       font-size: 25px;
       font-weight: 700;
    }
 
    .btn-img-delivery {
       /* width:215px;  */
       font-size: 100px;
    }
 
    .btn-card-delivery {
       color: #0D6EFD;
       padding: 5.5rem 23px;
 
    }
 
 
    /* ~ Categories */
 
 
    /** Btn Shopping Cart  */
    .btn-cart {
       fill: #fff;
       cursor: pointer;
       /* background-color: var(--primary-vending); */
       margin-top: 10px;
       padding: 12px;
       width: 70px;
       height: 70px;
       border-radius: 50%;
       -webkit-border-radius: 50%;
       -moz-border-radius: 50%;
       -ms-border-radius: 50%;
       -o-border-radius: 50%;
    }
 
    .kiosk-count {
       top: -12px;
       left: -22px;
       font-size: 20px;
    }
 
    .nav-button {
       font-size: 2rem;
       transform: translateY(-50%);
       background-color: rgba(255, 255, 255, .15);
       -webkit-backdrop-filter: blur(5px);
       backdrop-filter: blur(5px);
       color: #fff;
       padding: 4px 12px;
       cursor: pointer;
    }
 
    /* ~ Buttons Exits */
    .button-ft_0 {
       fill: var(--blanco);
       cursor: pointer;
       background-color: var(--primario);
       width: 150px;
       height: 150px;
       padding: 20px 15px 20px 5px;
       border-radius: 0% 100% 0% 100% / 100% 100% 0% 0%;
       -webkit-border-radius: 0% 100% 0% 100% / 100% 100% 0% 0%;
       -moz-border-radius: 0% 100% 0% 100% / 100% 100% 0% 0%;
       -ms-border-radius: 0% 100% 0% 100% / 100% 100% 0% 0%;
       -o-border-radius: 0% 100% 0% 100% / 100% 100% 0% 0%;
    }
 
 
    .button-ft_1 {
       fill: var(--blanco);
       cursor: pointer;
       background-color: var(--primary-vending);
       width: 75px;
       height: 75px;
       border-radius: 50%;
       -webkit-border-radius: 50%;
       -moz-border-radius: 50%;
       -ms-border-radius: 50%;
       -o-border-radius: 50%;
    }
 
 
    .button-ft,
    .button-ft_0:active,
    .button-ft_1:active {
       transform: scale(.92);
    }
 
    .kiosk-text-categories {
       font-size: 17px;
       font-weight: 800;
    }
 
    /*~ Productos */
 
    .image-empty {
       width: 100px;
       height: 100px;
       opacity: .50;
    }
 
    .kiosk-text-product {
       width: 104px;
       font-size: 15px;
       font-weight: 800;
    }
 
    .kiosk-card-body {
       flex: 1 1 auto;
       padding: 0px 0px;
       color: var(--bs-card-color);
    }
 
    .kiosk-text-card-product {
       font-size: 15px;
    }
 
    .card-img-top-products {
       width: 100%;
       height: 100px;
       object-fit: cover;
       border-radius: 10px 10px 0px 0px;
       -webkit-border-radius: 10px 10px 0px 0px;
       -moz-border-radius: 10px 10px 0px 0px;
       -ms-border-radius: 10px 10px 0px 0px;
       -o-border-radius: 10px 10px 0px 0px;
    }
 
    /*~ lIST CATEGORIES in porducts  */
    .container-list-kiosk {
       height: 110px;
       padding: 2px 0px;
    }
 
    .container-list-kiosk label {
       width: auto;
       font-size: 15px;
       font-weight: 700;
    }
 
    .button-container-kk img {
       width: 20px;
       height: 20px;
       margin: 5px;
       display: inline;
    }
 
    .container-list-kiosk ul {
       list-style: none;
       margin: 5px;
       padding: 0;
    }
 
    .container-list-kiosk li {
       display: inline-block;
       margin: 0px 0px;
    }
 
    /* Aplica un margen de 10px a todos los elementos de la lista */
    .kiosk-card-item_product {
       margin: 0px;
    }
 
    /* Aplica un margen izquierdo de 20px a los elementos mayores que el tercer hijo */
    .kiosk-card-item_product:nth-child(n + 4) {
       margin-top: 20px;
    }
 
 
    /*~ Info Products */
 
    .kiosk-segments-backgound {
       position: relative;
       width: 100%;
       height: 300px;
       box-shadow: rgba(9, 30, 66, 0.25) 0px 1px 1px, rgba(9, 30, 66, 0.13) 0px 0px 1px 1px;
       /* border-radius: 40px;
       -webkit-border-radius: 40px;
       -moz-border-radius: 40px;
       -ms-border-radius: 40px;
       -o-border-radius: 40px; */
       background-repeat: no-repeat;
       background-position: center;
       background-size: cover;
    }
 
    .product-card-tv {
       width: 100%;
       height: 300px;
       /* border-radius: 40px;
       -webkit-border-radius: 40px;
       -moz-border-radius: 40px;
       -ms-border-radius: 40px;
       -o-border-radius: 40px; */
       background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgb(0, 0, 0) 100%);
       position: absolute;
       bottom: 0;
 
    }
 
 
    .kiosk-description-product {
       font-size: 15px;
       font-weight: 600;
    }
 
    /* ~List Crad Option Segments */
    label.radio-card {
       cursor: pointer;
    }
 
    label.radio-card .card-content-wrapper {
       scale: 1;
       background: #ffffff;
       border: solid 2px #e3e3e3;
       border-radius: 9px;
       width: 114px;
       height: 210px;
       padding: 5px;
       position: relative;
       display: grid;
       box-shadow: 0 2px 4px 0 rgba(219, 215, 215, 0.04);
       transition: 200ms linear;
       -webkit-border-radius: 9px;
       -moz-border-radius: 9px;
       -ms-border-radius: 9px;
       -o-border-radius: 9px;
    }
 
    label.radio-card .check-icon {
       width: 10px;
       height: 10px;
       display: flex;
       border: solid 2px #e3e3e3;
       border-radius: 50%;
       transition: 200ms linear;
       position: relative;
       justify-content: flex-end;
       -webkit-border-radius: 50%;
       -moz-border-radius: 50%;
       -ms-border-radius: 50%;
       -o-border-radius: 50%;
    }
 
    label.radio-card .check-icon:before {
       content: "";
 
       /* background-image: url("data:image/svg+xml,%3Csvg width='12' height='9' viewBox='0 0 12 9' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0.93552 4.58423C0.890286 4.53718 0.854262 4.48209 0.829309 4.42179C0.779553 4.28741 0.779553 4.13965 0.829309 4.00527C0.853759 3.94471 0.889842 3.88952 0.93552 3.84283L1.68941 3.12018C1.73378 3.06821 1.7893 3.02692 1.85185 2.99939C1.91206 2.97215 1.97736 2.95796 2.04345 2.95774C2.11507 2.95635 2.18613 2.97056 2.2517 2.99939C2.31652 3.02822 2.3752 3.06922 2.42456 3.12018L4.69872 5.39851L9.58026 0.516971C9.62828 0.466328 9.68554 0.42533 9.74895 0.396182C9.81468 0.367844 9.88563 0.353653 9.95721 0.354531C10.0244 0.354903 10.0907 0.369582 10.1517 0.397592C10.2128 0.425602 10.2672 0.466298 10.3112 0.516971L11.0651 1.25003C11.1108 1.29672 11.1469 1.35191 11.1713 1.41247C11.2211 1.54686 11.2211 1.69461 11.1713 1.82899C11.1464 1.88929 11.1104 1.94439 11.0651 1.99143L5.06525 7.96007C5.02054 8.0122 4.96514 8.0541 4.90281 8.08294C4.76944 8.13802 4.61967 8.13802 4.4863 8.08294C4.42397 8.0541 4.36857 8.0122 4.32386 7.96007L0.93552 4.58423Z' fill='white'/%3E%3C/svg%3E%0A");
    background-repeat: no-repeat;
    background-size: 7px;
    background-position: center center;
    transform: scale(1.6);
    transition: 200ms linear; */
       opacity: 0;
    }
 
    label.radio-card input[type=radio] {
       visibility: hidden;
       appearance: none;
       -webkit-appearance: none;
       -moz-appearance: none;
    }
 
    label.radio-card input[type=radio]:checked+.card-content-wrapper {
       border: 3px solid #0045f4;
       color: #000000;
       background-color: #4e80ff15;
       /*background: #055aaf;
     */
       /*background: -webkit-linear-gradient(to bottom, #042dbf, #0575E6);
     background: linear-gradient(to bottom, #042dbf, #0575E6);
     */
 
       border-radius: 10px;
       -webkit-border-radius: 10px;
       -moz-border-radius: 10x;
       -ms-border-radius: 10x;
       -o-border-radius: 10x;
    }
 
    label.radio-card input[type=radio]:checked+.card-content-wrapper .check-icon {
       background: #3057d5;
       border-color: #3056d5c5;
       transform: scale(1.2);
    }
 
    label.radio-card input[type=radio]:checked+.card-content-wrapper .check-icon:before {
       transform: scale(1);
       opacity: 1;
    }
 
    label.radio-card input[type=radio]:focus+.card-content-wrapper .check-icon {
       box-shadow: 0 0 0 4px rgba(48, 86, 213, 0.2);
       border-color: #3056d5;
    }
 
    label.radio-card .card-content img {
       width: 100px;
       height: 100px;
       margin-bottom: 10px;
       border-radius: 10px;
       -webkit-border-radius: 10px;
       -moz-border-radius: 10px;
       -ms-border-radius: 10px;
       -o-border-radius: 10px;
       scale: 1;
    }
 
    label.radio-card .card-content h4 {
       width: 99px;
       font-size: 14px;
       letter-spacing: -0.24px;
       text-align: start;
       font-weight: 500;
       color: #000;
       margin-bottom: 10px;
       font-weight: 800;
    }
 
    label.radio-card .card-content h5 {
       font-size: 18px;
       line-height: 1.4;
 
       color: #22a009;
       font-weight: 800;
    }
 
    .kiosk-lista-container-product {
       padding: 1px 0px 0px 0px;
       /* border: 2px solid red; */
       overflow-x: auto;
    }
 
    .container-card-content-wrapper {
       margin-bottom: 2px;
 
    }
 
    .container-card-content-wrapper:last-child {
       margin-bottom: 80px;
    }
 
    /* ~ Order Summary */
    .title-order-section {
       font-size: 1.7rem;
       font-weight: 800;
    }
 
    .button-discont {
       font-size: 1.2rem
    }
 
    .order-item {
       margin: 20px 10px;
    }
 
    .order-item:first-child {
       margin: 10px 10px;
    }
 
    .img-order-summary {
       width: 80px;
       height: 80px;
    }
 
    .title-product-text {
       font-size: 18px;
    }
 
    .summary-text {
       font-size: 14px;
 
    }
 
    .detail-text {
       font-size: 13px;
    }
 
    .btn-dash {
       background-color: red;
    }
 
    .btn-plus {
       background-color: green;
    }
 
    .btn-dash:active {
       transform: scale(.85);
       -webkit-transform: scale(.85);
       -moz-transform: scale(.85);
       -ms-transform: scale(.85);
       -o-transform: scale(.85);
    }
 
    .btn-plus:active {
       transform: scale(.85);
       -webkit-transform: scale(.85);
       -moz-transform: scale(.85);
       -ms-transform: scale(.85);
       -o-transform: scale(.85);
    }
 
    .count-items {
       font-size: 25px;
    }
 
    .dash,
    .plus {
       color: white;
       font-size: 15px;
       font-weight: 800;
       padding: 5px;
    }
 
    .btn-add-order {
       font-size: 16px;
       font-weight: 700;
       padding: 8px;
    }
 
    .btn-cancel-order {
       font-size: 18px;
       font-weight: 700;
       padding: 12px;
    }
 
    .btn-send-order {
       font-size: 18px;
       font-weight: 700;
       padding: 12px;
    }
 
    .dash-icon,
    .plus-icon {
       color: white;
       font-size: 15px;
       font-weight: 800;
       padding: 1px 5px;
       border-radius: 20px;
       -webkit-border-radius: 20px;
       -moz-border-radius: 20px;
       -ms-border-radius: 20px;
       -o-border-radius: 20px;
    }
 
    .text-count-orders {
       font-size: 15px;
    }
 
    .text-msg-empty-orders {
       font-size: 20px;
    }
 
 
    .text-total-order {
       font-size: 26px;
    }
 
    .text-subtitel-order {
       font-size: 13.5px;
    }
 
    /* Modal edit user  */
    .kiosk-key-letter {
       font-size: 12px;
       font-weight: 800;
       padding: 7px 11px;
       border: 2px solid var(--new-main-8);
       border-radius: 30px;
       -webkit-border-radius: 30px;
       -moz-border-radius: 30px;
       -ms-border-radius: 30px;
       -o-border-radius: 30px;
       margin: 2px;
    }
 
    .keyboard-pad>.num,
    .delete {
       color: #000;
       font-weight: 700;
       font-size: 28px;
       border-radius: 10px;
       -webkit-border-radius: 10px;
       -moz-border-radius: 10px;
       -ms-border-radius: 10px;
       -o-border-radius: 10px;
       padding: 18px 0;
       width: calc(33.333% - 8px);
       margin: 0 4px 8px;
       text-align: center;
       background-color: rgba(241, 241, 241, 0.8);
       cursor: pointer;
       transition: all 0.2s ease-in-out;
       -webkit-transition: all 0.2s ease-in-out;
       -moz-transition: all 0.2s ease-in-out;
       -ms-transition: all 0.2s ease-in-out;
       -o-transition: all 0.2s ease-in-out;
       border: 1px solid rgb(226, 226, 226);
       box-shadow: 2px 2px 0 rgb(0, 0, 0, .2);
       inset: 4px -4px 0px #d4c69d;
       inset: -4px 4px 0px #beb28d;
    }
 
    .keypadPrimary-input_1 {
       color: var(--primary-vending);
       width: 100%;
       margin: 30px 0px;
       padding: 15px 0;
       font-size: 25px;
       font-weight: 700;
       text-align: center;
       border: 2px solid rgb(226, 226, 226);
       letter-spacing: 25px;
    }
 
 
    /* ~Status Payments  */
 
    .msg-pay {
       margin: 1px 0;
    }
    .loading-status-pay {
       width: 100px;
       height: 120px;
 
    }
 
    .radio-tile-group .input-container .radio-tile-label {
       text-align: center;
       font-size: 17px;
       font-weight: 600;
       text-transform: uppercase;
       letter-spacing: 1px;
    }
 
    .img-pay-met-kk {
       width: 135px;
       height: 100px;
       object-fit: contain;
    }
 
    /* ~Modal discount */
    /* ~Modal Discounts */
 
    .kiosk-img-discount {
       width: 128px;
       height: 128px;
    }
 
    .kiosk-text-discount {
       font-size: 20px;
       font-weight: 800;
       margin: 1px 0px 0px 0px;
    }
 
    .kiosk-delivery_header {
       padding: 3px;
       margin: 10px 0px;
       display: flex;
       justify-content: center;
       /* border: 2px solid #3c5ba9; */
       border-radius: 30px;
       -webkit-border-radius: 30px;
       -moz-border-radius: 30px;
       -ms-border-radius: 30px;
       -o-border-radius: 30px;
    }
 
    .kiosk-subtitle-md {
       font-size: 19px;
       font-weight: 800;
    }
 
 
 }
 
 /* SECTION: MOBILE SMALL */
 @media screen and (max-width:390px) {
 
    /* Utilits */
    ::-webkit-scrollbar {
       display: none;
    }
 
    .kiosk-container {
       background-color: #17ce20;
       width: 100%;
       height: 100%;
       display: flex;
       flex-direction: column;
       flex-wrap: nowrap;
       /* align-content: flex-start; */
    }
 
    .kiosk-container_section_lista {
       padding: 50px 10px;
       /* border: 2px solid red; */
       overflow-x: auto;
    }
 
    .kiosk-card-item {
       width: 7rem;
       height: 530px;
       border-radius: 10px;
       -webkit-border-radius: 10px;
       -moz-border-radius: 10px;
       -ms-border-radius: 10px;
       -o-border-radius: 10px;
    }
 
    .kiosk-text-card {
       font-size: 25px;
       font-weight: 700;
    }
 
    /*~ Delivery */
    .delivery {
       margin-top: 0px;
    }
 
    .kiosk-delivery_title {
       font-size: 17px;
       font-weight: 800;
       margin: 16px 0px 0px 0px;
    }
 
 
    .kiosk-delivery_btns>button {
       margin: 20px;
       border-radius: 40px;
       -webkit-border-radius: 40px;
       -moz-border-radius: 40px;
       -ms-border-radius: 40px;
       -o-border-radius: 40px;
    }
 
    .kiosk-text-card {
       font-size: 13px;
       font-weight: 700;
    }
 
    .kiosk-text-delivery {
       font-size: 18px;
       font-weight: 700;
    }
 
    .btn-img-delivery {
       /* width:215px;  */
       font-size: 100px;
    }
 
    .btn-card-delivery {
       color: #0D6EFD;
       padding: 2.5rem 25px;
 
    }
 
 
    /** Btn Shopping Cart  */
 
 
    .kiosk-count {
       top: -8px;
       left: -16px;
       font-size: 15px;
    }
 
    .btn-cart {
       fill: #fff;
       cursor: pointer;
       /* background-color: var(--primary-vending); */
       margin-top: 10px;
       padding: 12px;
       width: 57px;
       height: 57px;
       border-radius: 50%;
       -webkit-border-radius: 50%;
       -moz-border-radius: 50%;
       -ms-border-radius: 50%;
       -o-border-radius: 50%;
    }
 
    .button-ft_1 {
       fill: var(--blanco);
       cursor: pointer;
       background-color: var(--primary-vending);
       width: 60px;
       height: 60px;
       border-radius: 50%;
       -webkit-border-radius: 50%;
       -moz-border-radius: 50%;
       -ms-border-radius: 50%;
       -o-border-radius: 50%;
    }
 
    .kiosk-text-categories {
       text-align: center;
       font-size: 14px;
       font-weight: 800;
    }
 
 
    /*~ lIST CATEGORIES in porducts  */
 
    .kiosk-delivery_header_img {
       width: 100px;
       height: 40px;
       object-fit: cover;
       /* filter: drop-shadow(0 2px 5px rgba(158, 158, 158, 0.7)); */
    }
 
    .container-list-kiosk {
       height: 88px;
       padding: 2px 0px;
    }
 
    .container-list-kiosk label {
       width: auto;
       font-size: 15px;
       font-weight: 700;
    }
 
    .kiosk-text-product {
       width: 90px;
       font-size: 15px;
       font-weight: 800;
    }
 
    .button-container-kk img {
       width: 20px;
       height: 20px;
       margin: 5px;
       display: inline;
    }
 
    .container-list-kiosk li {
       display: inline-block;
       margin: 0px 0px;
    }
 
    /*~ Info Products */
 
    .kiosk-segments-backgound {
       position: relative;
       width: 100%;
       height: 200px;
       box-shadow: rgba(9, 30, 66, 0.25) 0px 1px 1px, rgba(9, 30, 66, 0.13) 0px 0px 1px 1px;
       /* border-radius: 40px;
       -webkit-border-radius: 40px;
       -moz-border-radius: 40px;
       -ms-border-radius: 40px;
       -o-border-radius: 40px; */
       background-repeat: no-repeat;
       background-position: center;
       background-size: cover;
    }
 
    .product-card-tv {
       border: 0px solid #000;
       width: 100%;
       height: 200px;
       /* border-radius: 40px;
       -webkit-border-radius: 40px;
       -moz-border-radius: 40px;
       -ms-border-radius: 40px;
       -o-border-radius: 40px; */
       background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgb(0, 0, 0) 100%);
       position: absolute;
       bottom: 0;
 
    }
 
    .card-img-top>img {
       width: 100%;
       height: 120px;
       object-fit: cover;
       border-radius: 15px 15px 0px 0px !important;
       -webkit-border-radius: 15px 15px 0px 0px !important;
       -moz-border-radius: 15px 15px 0px 0px !important;
       -ms-border-radius: 15px 15px 0px 0px !important;
       -o-border-radius: 15px 15px 0px 0px !important;
    }
 
 
    .kiosk-description-product {
       font-size: 15px;
       font-weight: 600;
    }
 
 
    /* ~ Order Summary */
    details[open]>summary~* {
       display: hidden;
    }
 
    .title-order-section {
       font-size: 1.5rem;
       font-weight: 800;
    }
 
    .button-discont {
       font-size: 1.1rem
    }
 
    .order-item {
       margin: 20px 10px;
    }
 
    .order-item:first-child {
       margin: 10px 10px;
    }
 
    .img-order-summary {
       width: 70px;
       height: 70px;
    }
 
    .title-product-text {
       font-size: 16px;
    }
 
    .summary-text {
       font-size: 12px;
 
    }
 
    .detail-text {
       font-size: 12px;
    }
 
    .btn-dash {
       background-color: red;
    }
 
    .btn-plus {
       background-color: green;
    }
 
    .btn-dash:active {
       transform: scale(.85);
       -webkit-transform: scale(.85);
       -moz-transform: scale(.85);
       -ms-transform: scale(.85);
       -o-transform: scale(.85);
    }
 
    .btn-plus:active {
       transform: scale(.85);
       -webkit-transform: scale(.85);
       -moz-transform: scale(.85);
       -ms-transform: scale(.85);
       -o-transform: scale(.85);
    }
 
    .dash,
    .plus {
       color: white;
       font-size: 15px;
       font-weight: 800;
       padding: 5px;
    }
 
    .btn-add-order {
       font-size: 16px;
       font-weight: 700;
       padding: 8px;
    }
 
    .btn-cancel-order {
       font-size: 18px;
       font-weight: 700;
       padding: 12px;
    }
 
    .btn-send-order {
       font-size: 18px;
       font-weight: 700;
       padding: 12px;
    }
 
    .text-total-order {
       font-size: 18px;
    }
 
    .dash-icon,
    .plus-icon {
       color: white;
       font-size: 15px;
       font-weight: 800;
       padding: 1px 5px;
       border-radius: 20px;
       -webkit-border-radius: 20px;
       -moz-border-radius: 20px;
       -ms-border-radius: 20px;
       -o-border-radius: 20px;
    }
 
    .count-items {
       font-size: 20px;
    }
 
    .text-count-orders {
       font-size: 15px;
    }
 
    .text-msg-empty-orders {
       font-size: 17px;
    }
 
    .text-total-order {
       font-size: 20px;
    }
 
    .text-subtitel-order {
       font-size: 13.5px;
    }
 
    /* ~Paisanitos keyboard */
    .keyboard-pad>.num,
    .delete {
       color: #000;
       font-weight: 700;
       font-size: 19px;
       border-radius: 10px;
       -webkit-border-radius: 10px;
       -moz-border-radius: 10px;
       -ms-border-radius: 10px;
       -o-border-radius: 10px;
       padding: 17px 0;
       width: calc(33.333% - 8px);
       margin: 0 4px 8px;
       text-align: center;
       background-color: rgba(241, 241, 241, 0.8);
       cursor: pointer;
       transition: all 0.2s linear;
       -webkit-transition: all 0.2s linear;
       -moz-transition: all 0.2s linear;
       -ms-transition: all 0.2s linear;
       -o-transition: all 0.2s linear;
       border: 1px solid rgb(226, 226, 226);
       box-shadow: 2px 2px 0 rgb(0, 0, 0, .2);
       inset: 4px -4px 0px #d4c69d;
       inset: -4px 4px 0px #beb28d;
    }
 
 
    /* ~Payments Methods */
 
    .radio-tile-group .input-container .radio-tile-label {
       text-align: center;
       width: 120px;
       /* Ancho máximo del contenedor */
       white-space: nowrap;
       /* Evita que el texto se divida en varias líneas */
       overflow: hidden;
       /* Oculta el contenido que se desborda del contenedor */
       text-overflow: ellipsis;
       font-size: 20px;
       font-weight: 600;
       text-transform: uppercase;
       letter-spacing: 1px;
 
    }
 
    .radio-tile-group .input-container .radio-tile {
       display: flex;
       flex-direction: column;
       align-items: center;
       justify-content: center;
       width: 100%;
       height: 100%;
       /* background: #fff; */
       border: 5px solid #e8e8e8;
       border-radius: 30px;
       padding: 25px 12px;
    }
 
    .img-pay-met-kk {
       width: 135px;
       height: 70px;
       object-fit: contain;
    }
 
    /* ~Modal User */
    .kiosk-key-letter {
       font-size: 12px;
       font-weight: 800;
       padding: 7px 10px;
       border: 2px solid var(--new-main-8);
       border-radius: 30px;
       -webkit-border-radius: 30px;
       -moz-border-radius: 30px;
       -ms-border-radius: 30px;
       -o-border-radius: 30px;
       margin: 2px;
    }
 
    .keypadPrimary-input_1 {
       color: var(--primary-vending);
       width: 100%;
       margin: .5rem 0;
       padding: 15px 0;
       font-size: 25px;
       font-weight: 700;
       text-align: center;
       border: 2px solid rgb(226, 226, 226);
       letter-spacing: 25px;
    }
 
    /* ~Status Payments  */
 
    .msg-pay {
       margin: 0rem 0;
    }
    .img-check-pay {
       width: 61px;
       height: 58px;
   }
 
 
    /* ~Modal Discounts */
 
    .kiosk-img-discount {
       width: 100px;
       height: 100px;
    }
 
    .kiosk-text-discount {
       font-size: 18px;
       font-weight: 800;
       margin: 1px 0px 0px 0px;
    }
 
    .kiosk-delivery_header {
       padding: 3px;
       margin: 10px 0px;
       display: flex;
       justify-content: center;
       /* border: 2px solid #3c5ba9; */
       border-radius: 30px;
       -webkit-border-radius: 30px;
       -moz-border-radius: 30px;
       -ms-border-radius: 30px;
       -o-border-radius: 30px;
    }
 
    .kiosk-subtitle-md {
       font-size: 16px;
       font-weight: 800;
    }
 
    
    .loading-status-pay {
       width: 60px;
       height: 60px;
 
    }
 
 }
 
 @media (width <=640px) {
 
 
    .kiosk-header-img-categories {
       width: 60px;
       filter: drop-shadow(2px 2px 0px rgb(255, 255, 255));
       -webkit-filter: drop-shadow(2px 2px 0px rgb(255, 255, 255));
    }
 
 
 
    /* .btn-language-kk {
        width: 120px; 
    } */
 
    .display-5-kk {
 
       font-size: 20px;
 
    }
 
 
 
    .kiosk-text-title-lang {
       font-size: 25px;
    }
 
 
    .kiosk-lista_items:last-child {
       padding-right: 20px;
    }
 
    .count-kiosk {
       font-size: 1.5rem;
    }
 
 
 
 
    .icon-delete-kk {
       font-size: 20px;
    }
 
 }
 
 
 
 /* Section: +++++ Animations BLOCK   +++++ */
 /* Title: +++++ Animation +++++ */
 
 .scale-up-left {
    animation: scale-up-left 0.4s;
 }
 
 @keyframes scale-up-left {
    0% {
       transform: scale(.5);
       transform-origin: left center
    }
 
    100% {
       transform: scale(1);
       transform-origin: left center
    }
 }
 
 
 
 .bounce-left {
    animation: bounce-left 1.2s linear both;
    transition: 0.5s ease-in
 }
 
 @keyframes bounce-left {
    0% {
       transform: translateX(-48px);
       animation-timing-function: ease-in;
       opacity: 1
    }
 
    24% {
       opacity: 1
    }
 
    40% {
       transform: translateX(-26px);
       animation-timing-function: ease-in
    }
 
    65% {
       transform: translateX(-13px);
       animation-timing-function: ease-in
    }
 
    82% {
       transform: translateX(-6.5px);
       animation-timing-function: ease-in
    }
 
    93% {
       transform: translateX(-4px);
       animation-timing-function: ease-in
    }
 
    25%,
    55%,
    75%,
    87%,
    98% {
       transform: translateX(0);
       animation-timing-function: ease-out
    }
 
    100% {
       transform: translateX(0);
       animation-timing-function: ease-out;
       opacity: 1
    }
 }
 
 
 
 
 
 
 
 
 
 
 .title-section {
    bottom: 0;
    left: 0;
    margin: auto;
 }